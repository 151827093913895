import { useEffect, useState } from 'react';
import { Utility } from '../utility/utility';
import { useLocation } from 'react-router-dom';

const getCachedLocale = () => {
  const cachedJson = window.localStorage.getItem('fetched-locale');

  if (cachedJson) {
    const cached = JSON.parse(cachedJson);
    cached.time = new Date(cached.time).getTime();
    if (Date.now() - cached.time < 1000 * 60 * 60 * 24) {
      return cached.locale;
    }
  }
  return undefined;
};

/**
 * A hook that decides the locale of the client based upon the URL or if that fails the cache or if that fails fetches the locale
 * @param {boolean} useCache decides if the cache should be used or not
 * @returns {string | undefined} the locale of the client, undefined if it is not yet decided
 */
export const useLocale = (useCache = true) => {
  const [rendered, setRendered] = useState(false);
  const [locale, setLocale] = useState(getCachedLocale());
  const location = useLocation();

  useEffect(() => {
    const pathParts = location.pathname.split('/').filter((x) => x && x.trim() !== '');
    const tempLocale = Utility.isLocaleValid(pathParts[0]) ? pathParts[0] : undefined;
    if (tempLocale && tempLocale !== locale) {
      setLocale(tempLocale);
      return;
    }
    if (locale) return;
    Utility.fetchLocale().then((locale) => {
      setLocale(locale);
      window.localStorage.setItem('fetched-locale', JSON.stringify({ locale, time: Date.now() }));
    });
  }, [location]);

  if (!rendered) {
    setRendered(true);
    const pathParts = location.pathname.split('/').filter((x) => x && x.trim() !== '');
    if (pathParts.length > 0 && Utility.isLocaleValid(pathParts[0])) {
      setLocale(pathParts[0]);
    } else if (useCache) {
      const cached = getCachedLocale();
      if (cached) {
        setLocale(cached);
      }
    }
  }

  return locale;
};
