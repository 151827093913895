import React, { useEffect, useState } from 'react';
import './NeedHelp.css';
import { useParams } from 'react-router-dom';

function NeedHelp(props) {
  let para = useParams();

  let [translations, settranslations] = useState({ data: [] });
  let [showChat, setShowChat] = useState(false);
  let param = useParams();
  let la;
  let arrla;
  let arrla1 = '';
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=NeedHelpText&populate=CardImage',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        settranslations(json);
      });
  }, []);

  useEffect(() => {
    if (props.color == 'grey' && translations.data.length > 0) {
      document.querySelector('.NeedHelp-wrap').style =
        'background-color:#f2f3f4;margin-top:0px;margin-bottom:0px;padding-top:120px;padding-bottom:120px';
      if (document.body.clientWidth < 768) {
        document.querySelector('.NeedHelp-wrap').style =
          'padding-top:64px;padding-bottom:64px;background-color:#f2f3f4;margin-top:0px;margin-bottom:0px;';
      }
      document.querySelector('.NeedHelp-Head').style = 'background-color:#ffffff';
      document.getElementById('NeedHelp-weird-square-with-rotation').style = 'background-color:#ffffff';
    }
  }, [translations]);

  return (
    <>
      {translations.data.length > 0 && (
        <div className="NeedHelp-wrap">
          <div className="NeedHelp-inner">
            <div className="NeedHelp-Head">
              <div className="NeedHelp-img">
                <img
                  alt="service stock image"
                  width={120}
                  height={120}
                  src={
                    translations.data[0].attributes.CardImage.data
                      ? translations.data[0].attributes.CardImage.data.attributes.url
                      : 'https://strapiwmc4hstore.blob.core.windows.net/website-assets/NeedHelpImage.webp'
                  }
                />
              </div>
              <div className="NeedHelp-Head-text">
                <h2>{translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[0]}</h2>
                <p>{translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[1]}</p>
                <span id="NeedHelp-weird-square-with-rotation"></span>
              </div>
            </div>
            <div className="NeedHelp-cards">
              <div className="NeedHelp-card">
                <h3>{translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[2][0]}</h3>
                <div className="NeedHelp-card-actionline">
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.4694 7.53044C11.3997 7.46078 11.3443 7.37806 11.3066 7.28702C11.2688 7.19597 11.2494 7.09837 11.2494 6.99981C11.2494 6.90125 11.2688 6.80365 11.3066 6.71261C11.3443 6.62156 11.3997 6.53884 11.4694 6.46918L14.6897 3.24981H12.75C12.5511 3.24981 12.3603 3.17079 12.2197 3.03014C12.079 2.88949 12 2.69872 12 2.49981C12 2.3009 12.079 2.11013 12.2197 1.96948C12.3603 1.82883 12.5511 1.74981 12.75 1.74981H16.5C16.6989 1.74981 16.8897 1.82883 17.0303 1.96948C17.171 2.11013 17.25 2.3009 17.25 2.49981V6.24981C17.25 6.44872 17.171 6.63949 17.0303 6.78014C16.8897 6.92079 16.6989 6.99981 16.5 6.99981C16.3011 6.99981 16.1103 6.92079 15.9697 6.78014C15.829 6.63949 15.75 6.44872 15.75 6.24981V4.31012L12.5306 7.53044C12.461 7.60017 12.3783 7.65549 12.2872 7.69323C12.1962 7.73097 12.0986 7.7504 12 7.7504C11.9014 7.7504 11.8039 7.73097 11.7128 7.69323C11.6218 7.65549 11.539 7.60017 11.4694 7.53044ZM17.8444 12.8554L13.4278 10.8764L13.4156 10.8707C13.1864 10.7727 12.9362 10.7333 12.6879 10.7562C12.4396 10.7792 12.2009 10.8636 11.9934 11.002C11.969 11.0181 11.9455 11.0357 11.9231 11.0545L9.64407 12.9998C8.19845 12.2976 6.70595 10.8164 6.00376 9.3895L7.95188 7.07294C7.97063 7.0495 7.98845 7.02606 8.00532 7.00075C8.14072 6.79384 8.22287 6.55667 8.24446 6.31035C8.26605 6.06402 8.22641 5.81618 8.12907 5.58887V5.57762L6.14438 1.15356C6.0157 0.856621 5.79444 0.609262 5.51362 0.448409C5.2328 0.287556 4.9075 0.221836 4.58626 0.26106C3.31592 0.428223 2.14986 1.05209 1.30588 2.01615C0.461903 2.98021 -0.00228837 4.21852 8.4831e-06 5.49981C8.4831e-06 12.9436 6.05626 18.9998 13.5 18.9998C14.7813 19.0021 16.0196 18.5379 16.9837 17.6939C17.9477 16.85 18.5716 15.6839 18.7388 14.4136C18.7781 14.0924 18.7125 13.7672 18.5518 13.4864C18.3911 13.2056 18.144 12.9843 17.8472 12.8554H17.8444Z"
                      fill="#001020"
                    />
                  </svg>
                  <a
                    href={translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[2][1]}
                    hrefLang={para.count}
                  >
                    {translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[2][2]}
                  </a>
                </div>
              </div>
              {para.count != 'en' && para.count != 'es' && para.count != 'fr-fr' && (
                <div className="NeedHelp-card">
                  <h3>{translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[3][0]}</h3>
                  <div className="NeedHelp-card-actionline">
                    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.083 10.0002V17.5002C20.083 18.4947 19.6879 19.4486 18.9847 20.1518C18.2814 20.8551 17.3276 21.2502 16.333 21.2502H11.083C10.8841 21.2502 10.6933 21.1712 10.5527 21.0305C10.412 20.8899 10.333 20.6991 10.333 20.5002C10.333 20.3013 10.412 20.1105 10.5527 19.9698C10.6933 19.8292 10.8841 19.7502 11.083 19.7502H16.333C16.9297 19.7502 17.502 19.5131 17.924 19.0912C18.346 18.6692 18.583 18.0969 18.583 17.5002H16.333C15.7363 17.5002 15.164 17.2631 14.742 16.8412C14.3201 16.4192 14.083 15.8469 14.083 15.2502V11.5002C14.083 10.9034 14.3201 10.3311 14.742 9.90919C15.164 9.48723 15.7363 9.25018 16.333 9.25018H18.5502C18.4083 7.69106 17.8256 6.20451 16.8704 4.96416C15.9151 3.72381 14.6266 2.78087 13.1555 2.24549C11.6843 1.7101 10.0911 1.60437 8.56211 1.94065C7.03309 2.27692 5.63131 3.04132 4.52051 4.14455C3.13925 5.50759 2.28731 7.31713 2.11676 9.25018H4.33301C4.92975 9.25018 5.50204 9.48723 5.924 9.90919C6.34596 10.3311 6.58301 10.9034 6.58301 11.5002V15.2502C6.58301 15.8469 6.34596 16.4192 5.924 16.8412C5.50204 17.2631 4.92975 17.5002 4.33301 17.5002H2.83301C2.23627 17.5002 1.66397 17.2631 1.24202 16.8412C0.820061 16.4192 0.583008 15.8469 0.583008 15.2502V10.0002C0.585111 8.06883 1.16015 6.18153 2.23535 4.57714C3.31055 2.97276 4.83757 1.72343 6.62312 0.987287C8.40868 0.251145 10.3725 0.0612849 12.266 0.441739C14.1595 0.822194 15.8976 1.75586 17.2602 3.12455C18.1599 4.02859 18.8723 5.10126 19.3567 6.28111C19.8411 7.46095 20.0879 8.72478 20.083 10.0002Z"
                        fill="#001020"
                      />
                    </svg>

                    {(para.count == 'pt-br' ||
                      para.count == 'pt-pt' ||
                      para.count == 'fr-ch' ||
                      para.count == 'de-ch' ||
                      para.count == 'it-ch') && (
                      <a
                        id="NeedHelp-StartChat"
                        href={
                          translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[3][1]
                        }
                        target="_blank"
                        hrefLang={para.count}
                      >
                        {translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[3][2]}
                      </a>
                    )}
                    {para.count != 'pt-br' &&
                      para.count != 'pt-pt' &&
                      para.count != 'fr-ch' &&
                      para.count != 'de-ch' &&
                      para.count != 'it-ch' && (
                        <a
                          id="NeedHelp-StartChat"
                          onClick={() => setShowChat(!showChat)}
                          target="_blank"
                          hrefLang={para.count}
                        >
                          {translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[3][2]}
                        </a>
                      )}
                    {
                      <a
                        id="NeedHelp-StartChat-resp"
                        href={
                          translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[3][1]
                        }
                        target="_blank"
                        hrefLang={para.count}
                      >
                        {translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[3][2]}
                      </a>
                    }
                  </div>
                </div>
              )}
              <div className="NeedHelp-card">
                <h3>{translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[4][0]}</h3>
                <div className="NeedHelp-card-actionline">
                  <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20.0832 6.37562L11.0832 0.375624C10.96 0.293395 10.8152 0.249512 10.667 0.249512C10.5188 0.249512 10.374 0.293395 10.2507 0.375624L1.25074 6.37562C1.148 6.44417 1.06378 6.53703 1.00556 6.64595C0.947335 6.75488 0.916912 6.87649 0.916992 7V16.75C0.916992 17.1478 1.07503 17.5294 1.35633 17.8107C1.63764 18.092 2.01917 18.25 2.41699 18.25H18.917C19.3148 18.25 19.6963 18.092 19.9777 17.8107C20.259 17.5294 20.417 17.1478 20.417 16.75V7C20.4171 6.87649 20.3867 6.75488 20.3284 6.64595C20.2702 6.53703 20.186 6.44417 20.0832 6.37562ZM2.41699 16.75V8.45594L8.59512 12.8622C8.72232 12.953 8.87474 13.0019 9.03106 13.0019H12.3029C12.4592 13.0019 12.6117 12.953 12.7389 12.8622L18.917 8.45594V16.75H2.41699Z"
                      fill="#001020"
                    />
                  </svg>
                  <a
                    hrefLang={para.count}
                    href={translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[4][1]}
                  >
                    {translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[4][2]}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {translations.data.length == 0 && <div id="NeedHelp-shadowdiv"></div>}

      {translations.data.length > 0 &&
        para.count != 'pt-br' &&
        para.count != 'pt-pt' &&
        para.count != 'de-ch' &&
        para.count != 'it-ch' &&
        para.count != 'fr-ch' && (
          <>
            {translations.data.length > 0 && (
              <>
                {showChat && (
                  <>
                    <button id="CloseChatBtnHeader" onClick={() => setShowChat(false)}>
                      <img
                        width={24}
                        height={24}
                        src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/xiconweiss.png"
                      />
                    </button>
                    <div id="ChatIFrameLoading">
                      <img
                        src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/q-logo.png"
                        width="72"
                        height={'72'}
                      />
                    </div>
                    <iframe
                      src={translations.data[0].attributes.PageTranslations.data[3][1]}
                      style={{
                        width: '338px',
                        height: '520px',
                        position: 'fixed',
                        bottom: 0,
                        right: 0,
                        zIndex: 1000,
                        border: 'none',
                      }}
                      title="Chat"
                      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                      onLoad={() => {
                        if (document.getElementById('CloseChatBtnHeader')) {
                          document.getElementById('CloseChatBtnHeader').style = 'display:block !important';
                        }
                      }}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
    </>
  );
}

export default NeedHelp;
