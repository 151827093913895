import React from 'react';
import HeaderSlim from './includes/HeaderSlim';
import Footernew from './includes/Footernew';
import NewsletterNew from './includes/NewsletterNew';
import Block404 from './includes/Block404';
import { Helmet } from 'react-helmet';

function Page404() {
  return (
    <>
      <Helmet>
        <title>404 Page</title>
      </Helmet>
      <HeaderSlim></HeaderSlim>
      <Block404></Block404>

      <NewsletterNew color="grey"></NewsletterNew>
      <Footernew></Footernew>
    </>
  );
}

export default Page404;
