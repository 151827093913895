import React, { useEffect, useState } from 'react';

import HeaderSlim from './includes/HeaderSlim';
import './Jobcenter.css';
import { useParams, Link, useLocation } from 'react-router-dom';
import Footernew from './includes/Footernew';
import NeedHelp from './includes/NeedHelp';
import { Helmet } from 'react-helmet';

function Jobcenter() {
  const params = useParams();
  let param = useParams();
  let [systemres, setsystemres] = useState({ data: [] });
  let [pagetranslations, setpagetranslations] = useState({ data: [] });
  let [posicount, setposicount] = useState(0);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  let la;
  let arrla;
  let arrla1 = '';

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }
  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    };

    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=JobOverviewTranslations',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setpagetranslations(json);
      });

    fetch('https://strapi-qrailing.azurewebsites.net/api/jobs?locale=' + arrla1 + '&sort[0]=Title', requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setsystemres(json);
        setposicount(json.data.length);
      });
  }, []);

  function rendercards() {
    if (systemres.data.length > 0) {
      posicount = systemres.data.length;
      return (
        <>
          {systemres.data.map((item, index) => {
            return (
              <Link
                hrefLang={param.count}
                className="jobcenter-jobcard-outer-link"
                to={'/' + params.count + '/company/career/' + item.attributes.Slug}
              >
                <div key={'Key' + index} className="jobcenter-jobcard">
                  <div>
                    <h2>{item.attributes.Title}</h2>
                    <p>
                      {item.attributes.SubTitle} • {item.attributes.Location}
                    </p>
                  </div>
                  <div className="jobcenter-jobcard-desctiption">
                    <div className="jobcenter-jobcard-joblink">
                      <img src="https://q-railing.s3.eu-central-1.amazonaws.com/icons/simple-arrow-white.png" />
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}

          <div className="Jobcenter-fallback-wrap" id="JobNotAvailable">
            {pagetranslations.data.length > 0 && (
              <div className={systemres.data.length === 0 && 'PaddingTop'}>
                <h2>
                  {pagetranslations.data[0].attributes.FreeData3
                    ? pagetranslations.data[0].attributes.FreeData3.data[0]
                    : 'Nothing available for your Area?'}
                </h2>
                <p>
                  {pagetranslations.data[0].attributes.FreeData3 &&
                    pagetranslations.data[0].attributes.FreeData3.data[1]}
                </p>
                {pagetranslations.data[0].attributes.FreeData3 && (
                  <a href={'mailto:' + pagetranslations.data[0].attributes.FreeData3.data[2][1]}>
                    {pagetranslations.data[0].attributes.FreeData3.data[2][0]}
                  </a>
                )}
              </div>
            )}
          </div>
        </>
      );
    }
    return (
      <div className="Jobcenter-fallback-wrap">
        {pagetranslations.data.length > 0 && (
          <div className={systemres.data.length === 0 && 'PaddingTop'}>
            <h2>
              {pagetranslations.data[0].attributes.FreeData2
                ? pagetranslations.data[0].attributes.FreeData2.data[0]
                : 'Nothing available for your Area?'}
            </h2>
            <p>
              {pagetranslations.data[0].attributes.FreeData2 && pagetranslations.data[0].attributes.FreeData2.data[1]}
            </p>
            {pagetranslations.data[0].attributes.FreeData2 && (
              <a href={'mailto:' + pagetranslations.data[0].attributes.FreeData2.data[2][1]}>
                {pagetranslations.data[0].attributes.FreeData2.data[2][0]}
              </a>
            )}
          </div>
        )}
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>{pagetranslations.data.length > 0 ? pagetranslations.data[0].attributes.MetaTitle : 'Jobs'}</title>
        <meta
          name="description"
          content={pagetranslations.data.length > 0 ? pagetranslations.data[0].attributes.MetaText : 'Q-railing jobs'}
        ></meta>
      </Helmet>
      <HeaderSlim></HeaderSlim>
      <div className="jobcenter-wrap">
        <div className="jobcenter-header">
          <h1>{pagetranslations.data.length > 0 ? pagetranslations.data[0].attributes.FreeTitle1 : 'Careers'}</h1>
          <div className="jobcenter-header-subheader">
            <p>{pagetranslations.data.length > 0 && pagetranslations.data[0].attributes.FreeText1}</p>

            <div className="jobcenter-header-subheader-inner" id={systemres.data.length === 0 && 'hiddenid'}>
              <h3>
                {posicount}{' '}
                {pagetranslations.data.length > 0 && (
                  <>
                    {posicount == 1
                      ? pagetranslations.data[0].attributes.FreeData1.data[1]
                      : pagetranslations.data[0].attributes.FreeData1.data[0]}
                  </>
                )}
              </h3>
              <a
                onClick={() => {
                  document.querySelector('.jobcenter-jobcard').scrollIntoView({ behavior: 'smooth', block: 'center' });
                }}
                hrefLang={param.count}
              >
                <img
                  width="16"
                  height="16"
                  alt="arrow icon"
                  src="https://q-railing.s3.eu-central-1.amazonaws.com/icons/simple-arrow-white.png"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="jobcenter-banner">
          <div>
            <img
              alt="Job Banner-Image left"
              height="432"
              src="https://q-railing.s3.eu-central-1.amazonaws.com/images/job2.jpg"
            />
            <img
              alt="Job Banner-Image middle"
              width="648"
              height="432"
              src="https://q-railing.s3.eu-central-1.amazonaws.com/images/jobs1.jpg"
            />
            <img
              alt="Job Banner-Image right"
              height="432"
              src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/Skeb_744x864.webp"
            />
          </div>
        </div>
        <div className="jobcenter-inner">
          <h2 id="jobcenter-list-headline" className={systemres.data.length === 0 && 'hidden'}>
            {pagetranslations.data.length > 0 && (
              <>
                {posicount == 1
                  ? pagetranslations.data[0].attributes.FreeData1.data[2]
                  : pagetranslations.data[0].attributes.FreeData1.data[3]}
              </>
            )}{' '}
            <span>{posicount}</span>
          </h2>
          <div className="jobcenter-jobcards">{rendercards()}</div>
          <div className="jobcenter-notav-wrap" id={systemres.data.length === 0 && 'notavwrappadding'}>
            <div className="jobcenter-notav-inner"></div>
          </div>
        </div>
      </div>
      <NeedHelp color="grey"></NeedHelp>
      <Footernew></Footernew>
    </>
  );
}

export default Jobcenter;
