import React from 'react';
import './ThreeCardGrid.css';
function ThreeCardGrid(props) {
  let headline = props.dater.data[0].attributes.FreeData2.ThreeCardHeadline;
  let grid = props.dater.data[0].attributes.FreeData2.ThreeCardGrid;

  if (props.mode == 4) {
    headline = props.dater.data[0].attributes.FreeData3.FourCardHeadline;
    grid = props.dater.data[0].attributes.FreeData3.FourCardGrid;
  }
  return (
    <>
      {props.dater && (
        <div className="ThreeCardGrid-wrap" id={props.color == 'grey' && 'ThreeCardGrid-grey-wrap'}>
          <div className="ThreeCardGrid-inner">
            <div className="ThreeCardGrid-head">
              <h2>{headline[0]}</h2>
              <p>{headline[1]}</p>
            </div>

            <div className="ThreeCardGrid-grid" id={props.mode == 4 && 'ThreeCardGridFour'}>
              <div className="ThreeCardGrid-card">
                <img src={grid[0][0]} />
                <div>
                  <h3>{grid[0][1]}</h3>
                  {props.mode != 4 && <p>{grid[0][2]}</p>}
                  {props.mode == 4 && <a href={grid[0][2]}>{grid[0][3]}</a>}
                </div>
              </div>
              <div className="ThreeCardGrid-card">
                <img src={grid[1][0]} />
                <div>
                  <h3>{grid[1][1]}</h3>
                  {props.mode != 4 && <p>{grid[1][2]}</p>}
                  {props.mode == 4 && <a href={grid[1][2]}>{grid[1][3]}</a>}
                </div>
              </div>
              <div className="ThreeCardGrid-card">
                <img src={grid[2][0]} />
                <div>
                  <h3>{grid[2][1]}</h3>
                  {props.mode != 4 && <p>{grid[2][2]}</p>}
                  {props.mode == 4 && <a href={grid[2][2]}>{grid[2][3]}</a>}
                </div>
              </div>
              {props.mode == 4 && (
                <div className="ThreeCardGrid-card">
                  <img src={grid[3][0]} />
                  <div>
                    <h3>{grid[3][1]}</h3>
                    {props.mode != 4 && <p>{grid[3][2]}</p>}
                    {props.mode == 4 && <a href={grid[3][2]}>{grid[3][3]}</a>}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ThreeCardGrid;
