import './App.css';
import { Routes } from 'react-router-dom';
import './components/includes/Responsive.css';
import './components/KonfiguratorDetail.css';
import './components/includes/Header.css';
import React, { useEffect } from 'react';

import { RoutesComponent } from './utility/routing/routes-component';
import { MetaLocaleRoutes } from 'components/meta-tags/meta-locale-routes';

function App() {
  useEffect(() => {
    // Define the font URL(s) from your S3 bucket
    const fontUrl = 'https://strapiwmc4hstore.blob.core.windows.net/website-assets/Inter-Regular.ttf';

    // Create a link element for the font
    const fontLink = document.createElement('link');
    fontLink.href = fontUrl;
    fontLink.rel = 'stylesheet';

    // Append the link to the head of the document
    document.head.appendChild(fontLink);

    // Optionally, you can load multiple fonts by creating additional link elements

    return () => {
      // Clean up the link element when the component unmounts
      document.head.removeChild(fontLink);
    };
  }, []);

  return (
    <>
      <MetaLocaleRoutes />
      <Routes>{RoutesComponent()}</Routes>
    </>
  );
}

export default App;
