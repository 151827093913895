import React, { useState, useEffect } from 'react';
import './Howwework2.css';
import { useParams } from 'react-router-dom';

function Howwework2(props) {
  let [translations, settranslations] = useState({ data: [] });
  let param = useParams();
  let para = useParams();
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' + arrla1 + '&filters[Slug][$eq]=HowWeWorkText',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        settranslations(json);
      });
  }, []);

  useEffect(() => {
    if (translations.data.length > 0) {
      if (props.color == 'white') {
        document.querySelector('.howwework2-wrap').style = 'background-color:white !important;';
      } else {
        document.getElementById('howwework2-card1').style = 'border-right:3px solid #CCCFD2 !important;';
        document.getElementById('howwework2-card3').style = 'border-left:3px solid #CCCFD2 !important;';
        document.getElementById('howwework2-span01').style = 'color: #CCCFD2 !important;';
        document.getElementById('howwework2-span02').style = 'color: #CCCFD2 !important;';
        document.getElementById('howwework2-span03').style = 'color: #CCCFD2 !important;';
      }
    }
  }, [translations]);
  let la;
  let arrla;
  let arrla1 = '';
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  return (
    <>
      {translations.data.length == 0 && <div id="Howwework2-shadowdiv"></div>}
      {translations.data.length > 0 && (
        <div className="howwework2-wrap">
          {props.type != 'architect' && props.type != 'consumer' && (
            <div className="howwework2-inner">
              <div className="howwework2-header">
                <h2>{translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[0]}</h2>
                <div>
                  <p>{translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[1]}</p>

                  <a
                    id="howwework2-button"
                    hrefLang={para.count}
                    href={translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[2][1]}
                  >
                    {translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[2][0]}
                  </a>
                </div>
              </div>
              <div className="howwework2-cards">
                <div className="howwework2-card" id="howwework2-card1">
                  <span id="howwework2-span01">01</span>
                  <h3>{translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[3][0]}</h3>
                  <p>{translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[3][1]}</p>
                  <br />

                  <a
                    id="HowWeWorkLink1"
                    hrefLang={para.count}
                    href={translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[3][3]}
                  >
                    {translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[3][2]}
                  </a>
                </div>
                <div className="howwework2-card" id="howwework2-card2">
                  <span id="howwework2-span02">02</span>
                  <h3>{translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[4][0]}</h3>
                  <p>{translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[4][1]}</p>
                  <br />

                  <a
                    hrefLang={para.count}
                    id="HowWeWorkLink2"
                    href={translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[4][3]}
                  >
                    {translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[4][2]}
                  </a>
                </div>
                <div className="howwework2-card" id="howwework2-card3">
                  <span id="howwework2-span03">03</span>
                  <h3>{translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[5][0]}</h3>
                  <p>{translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[5][1]}</p>
                  <br />

                  <a
                    id="HowWeWorkLink3"
                    hrefLang={para.count}
                    href={translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[5][3]}
                  >
                    {translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[5][2]}
                  </a>
                </div>
              </div>

              {props.bogus == 'bogus' && (
                <a
                  id="howwework2-button-resp"
                  hrefLang={para.count}
                  href={translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[2][1]}
                >
                  {translations.data.length > 0 && translations.data[0].attributes.PageTranslations.data[2][0]}
                </a>
              )}
            </div>
          )}

          {props.type == 'architect' && (
            <div className="howwework2-inner">
              <div className="howwework2-header">
                <h2>{translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[0]}</h2>
                <div>
                  <p>{translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[1]}</p>
                  <a
                    id="howwework2-button"
                    hrefLang={para.count}
                    href={translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[2][1]}
                  >
                    {translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[2][0]}
                  </a>
                </div>
              </div>
              <div className="howwework2-cards">
                <div className="howwework2-card" id="howwework2-card1">
                  <span id="howwework2-span01">01</span>
                  <h3>{translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[3][0]}</h3>
                  <p>{translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[3][1]}</p>
                  <br />

                  <a
                    id="HowWeWorkLink1Architect"
                    hrefLang={para.count}
                    href={translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[3][3]}
                  >
                    {translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[3][2]}
                  </a>
                </div>
                <div className="howwework2-card" id="howwework2-card2">
                  <span id="howwework2-span02">02</span>
                  <h3>{translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[4][0]}</h3>
                  <p>{translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[4][1]}</p>
                  <br />

                  <a
                    id="HowWeWorkLink2Architect"
                    hrefLang={para.count}
                    href={translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[4][3]}
                  >
                    {translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[4][2]}
                  </a>
                </div>
                <div className="howwework2-card" id="howwework2-card3">
                  <span id="howwework2-span03">03</span>
                  <h3>{translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[5][0]}</h3>
                  <p>{translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[5][1]}</p>
                  <br />

                  <a
                    id="HowWeWorkLink3Architect"
                    hrefLang={para.count}
                    href={translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[5][3]}
                  >
                    {translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[5][2]}
                  </a>
                </div>
              </div>

              <a
                id="howwework2-button-resp"
                hrefLang={para.count}
                href={translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[2][1]}
              >
                {translations.data.length > 0 && translations.data[0].attributes.FreeData4.data[2][0]}
              </a>
            </div>
          )}

          {props.type == 'consumer' && (
            <div className="howwework2-inner">
              <div className="howwework2-header">
                <h2>{translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[0]}</h2>
                <div>
                  <p>{translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[1]}</p>
                  <a
                    id="howwework2-button"
                    hrefLang={para.count}
                    href={translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[2][1]}
                  >
                    {translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[2][0]}
                  </a>
                </div>
              </div>
              <div className="howwework2-cards">
                <div className="howwework2-card" id="howwework2-card1">
                  <span id="howwework2-span01">01</span>
                  <h3>{translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[3][0]}</h3>
                  <p>{translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[3][1]}</p>
                  <br />

                  <a
                    id="HowWeWorkLink1Consumer"
                    hrefLang={para.count}
                    href={translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[3][3]}
                  >
                    {translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[3][2]}
                  </a>
                </div>
                <div className="howwework2-card" id="howwework2-card2">
                  <span id="howwework2-span02">02</span>
                  <h3>{translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[4][0]}</h3>
                  <p>{translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[4][1]}</p>
                  <br />

                  <a
                    id="HowWeWorkLink2Consumer"
                    hrefLang={para.count}
                    href={translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[4][3]}
                  >
                    {translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[4][2]}
                  </a>
                </div>
                <div className="howwework2-card" id="howwework2-card3">
                  <span id="howwework2-span03">03</span>
                  <h3>{translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[5][0]}</h3>
                  <p>{translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[5][1]}</p>
                  <br />

                  <a
                    id="HowWeWorkLink3Consumer"
                    hrefLang={para.count}
                    href={translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[5][3]}
                  >
                    {translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[5][2]}
                  </a>
                </div>
              </div>

              <a
                id="howwework2-button-resp"
                hrefLang={para.count}
                href={translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[2][1]}
              >
                {translations.data.length > 0 && translations.data[0].attributes.FreeData5.data[2][0]}
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Howwework2;
