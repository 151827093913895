import './loading-screen.css';

/**
 *
 * @param {{scale: number | undefined, speed: number | undefined}} props
 * @returns the loading overlay
 */
export const LoadingScreen = ({ scale, speed }) => {
  scale ??= 1;
  speed ??= 1;
  speed = 1 / speed;
  return (
    <div className="LoadingOverlay-anchor">
      <img
        style={{ '--scale': scale, '--speed': speed }}
        className="LoadingOverlay-logo"
        src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/q_logo_white.webp"
        width={72}
        height={72}
        alt="Q-railing logo"
      />
    </div>
  );
};
