import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Servicesearch.css';
function ServiceGeneralSearch() {
  let para = useParams();
  let param = useParams();
  let la;
  let arrla;
  let arrla1 = '';
  const [animate] = useState(false);
  const translations = {
    'da-dk': 'Skriv her...',
    'de-at': 'Hier tippen...',
    'de-ch': 'Hier tippen...',
    'de-de': 'Hier tippen...',
    'x-default': 'Type here...',
    'en-ca': 'Type here...',
    'en-gb': 'Type here...',
    'en-ie': 'Type here...',
    'en-us': 'Type here...',
    es: 'Escribe aquí...',
    'es-es': 'Escribe aquí...',
    'es-us': 'Escribe aquí...',
    'fi-fi': 'Kirjoita tähän...',
    'fr-be': 'Tapez ici...',
    'fr-ca': 'Tapez ici...',
    'fr-ch': 'Tapez ici...',
    'fr-lu': 'Tapez ici...',
    'it-ch': 'Digita qui...',
    'it-it': 'Digita qui...',
    'nb-no': 'Skriv her...',
    'nl-nl': 'Typ hier...',
    'nl-be': 'Typ hier...',
    'pl-pl': 'Wpisz tutaj...',
    'pt-br': 'Digite aqui...',
    'pt-pt': 'Digite aqui...',
    'sv-se': 'Skriv här...',
  };

  const translations2 = {
    'da-dk': ['Søg i alle kategorier', 'Se alle systemer', 'Skjul systemer'],
    'de-at': ['Alle Kategorien durchsuchen', 'Alle Systeme anzeigen', 'Systeme einklappen'],
    'de-ch': ['Alle Kategorien durchsuchen', 'Alle Systeme anzeigen', 'Systeme einklappen'],
    'de-de': ['Alle Kategorien durchsuchen', 'Alle Systeme anzeigen', 'Systeme einklappen'],
    'x-default': ['Search all categories', 'View all systems', 'Collapse systems'],
    'en-ca': ['Search all categories', 'View all systems', 'Collapse systems'],
    'en-gb': ['Search all categories', 'View all systems', 'Collapse systems'],
    'en-ie': ['Search all categories', 'View all systems', 'Collapse systems'],
    'en-us': ['Search all categories', 'View all systems', 'Collapse systems'],
    es: ['Buscar en todas las categorías', 'Ver todos los sistemas', 'Ocultar sistemas'],
    'es-es': ['Buscar en todas las categorías', 'Ver todos los sistemas', 'Ocultar sistemas'],
    'es-us': ['Buscar en todas las categorías', 'Ver todos los sistemas', 'Ocultar sistemas'],
    'fi-fi': ['Hae kaikista kategorioista', 'Näytä kaikki järjestelmät', 'Piilota järjestelmät'],
    'fr-be': ['Rechercher dans toutes les catégories', 'Voir tous les systèmes', 'Réduire les systèmes'],
    'fr-ca': ['Rechercher dans toutes les catégories', 'Voir tous les systèmes', 'Réduire les systèmes'],
    'fr-ch': ['Rechercher dans toutes les catégories', 'Voir tous les systèmes', 'Réduire les systèmes'],
    'fr-lu': ['Rechercher dans toutes les catégories', 'Voir tous les systèmes', 'Réduire les systèmes'],
    'it-ch': ['Cerca in tutte le categorie', 'Visualizza tutti i sistemi', 'Nascondi sistemi'],
    'it-it': ['Cerca in tutte le categorie', 'Visualizza tutti i sistemi', 'Nascondi sistemi'],
    'nb-no': ['Søk i alle kategorier', 'Vis alle systemer', 'Skjul systemer'],
    'nl-nl': ['Zoek in alle categorieën', 'Bekijk alle systemen', 'Verberg systemen'],
    'nl-be': ['Zoek in alle categorieën', 'Bekijk alle systemen', 'Verberg systemen'],
    'pl-pl': ['Przeszukaj wszystkie kategorie', 'Zobacz wszystkie systemy', 'Ukryj systemy'],
    'pt-br': ['Pesquisar em todas as categorias', 'Ver todos os sistemas', 'Ocultar sistemas'],
    'pt-pt': ['Pesquisar em todas as categorias', 'Ver todos os sistemas', 'Ocultar sistemas'],
    'sv-se': ['Sök i alla kategorier', 'Visa alla system', 'Dölj system'],
  };
  const locale = para.count;
  const translatedTextTypeHere = translations[locale] || translations['x-default'];

  const translatedSpecialText = translations2[locale] || translations2['x-default'];

  let [currentDocsPage, setCurrentDocsPage] = useState(0);
  let [docshits, setdocshits] = useState(0);
  let [btnsdocs, setbtnsdocs] = useState([]);

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token2 = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions2 = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token2}` },
  };

  let [searchwords, setsearchwords] = useState('');

  let [searchfilter] = useState('all');
  let [finaldops, setfinaldops] = useState();
  let [arethereresults] = useState({ data: [] });
  let [pagelimitbotdocs] = useState(0);
  let [pagelimittopdocs] = useState(12);
  let [pagetranslations, setpagetranslations] = useState({ data: [] });
  useEffect(() => {
    try {
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
          arrla1 +
          '&filters[Slug][$eq]=GatheringWordTranslations',
        requestOptions2,
      )
        .then((res) => res.json())
        .then((json) => {
          setpagetranslations(json);
        });
    } catch {
      /* empty */
    }

    try {
      const cachedData = localStorage.getItem(para.count + '-cachedDataSystemCards');
      if (cachedData) {
        let now = new Date();

        if (now.getTime() > JSON.parse(cachedData).expiry) {
          localStorage.removeItem(para.count + '-cachedDataSystemCards');
        }
      } else {
        let now = new Date();

        fetch(
          'https://strapi-qrailing.azurewebsites.net/api/systems?locale=' +
            arrla1 +
            '&populate=CardImage&sort[0]=Priority:desc&pagination[pageSize]=50',
          requestOptions2,
        )
          .then((res) => res.json())
          .then((json) => {
            localStorage.setItem(
              para.count + '-cachedDataSystemCards',
              JSON.stringify({ json, expiry: now.getTime() + 24 * 60 * 60 * 1000 }),
            );
          });
      }
    } catch {
      /* empty */
    }
  }, []);

  useEffect(() => {
    returndocspagebtns();
  }, [finaldops, currentDocsPage]);
  useEffect(() => {
    searchdocs();
  }, [currentDocsPage]);

  let searchinput = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchwords) {
        setCurrentDocsPage(0);
        searchdocs(searchwords);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchwords]);
  useEffect(() => {
    searchdocs();
  }, [searchfilter]);
  useEffect(() => {
    returndocs();
  }, [finaldops]);

  async function searchdocs() {
    const url = 'https://qrailingsearch.space/indexes/' + para.count + '-downloads/search';
    const perPage = 12;
    if (searchwords.length > 1) {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer 3dc89833d80b98094d113cc0e4db06a4fcfd3011312fdae608b0e02a3e374cbc`,
          },
          body: JSON.stringify({
            q: searchwords,
            offset: currentDocsPage * perPage,
            limit: 12,
          }),
        });

        const data = await response.json();

        setdocshits(data.estimatedTotalHits);
        setfinaldops(data.hits);
        returndocs(data.hits);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  }

  function returndocspagebtns() {
    let buttons = [];
    if (docshits > 12) {
      let pages = Math.ceil(docshits / 12); // calculate the number of pages
      let startPage = Math.max(0, currentDocsPage - 3); // start from the current page minus 3
      let endPage = Math.min(pages, startPage + 7); // end at the start page plus 7

      // Adjust if we're at the start or end of the pages
      if (currentDocsPage < 4) {
        endPage = Math.min(pages, 7);
      }
      if (currentDocsPage > pages - 5) {
        startPage = Math.max(0, pages - 7);
      }

      // If startPage is not the first page, add a button for the first page and an ellipsis
      if (startPage > 0) {
        buttons.push(
          <button
            className={currentDocsPage === 0 ? 'paginationbutton active_pagebtn' : 'paginationbutton'}
            onClick={() => setCurrentDocsPage(0)}
          >
            1
          </button>,
        );
        buttons.push(<span className="points-divider-search">...</span>);
      }

      for (let i = startPage; i < endPage; i++) {
        buttons.push(
          <button
            className={currentDocsPage === i ? 'paginationbutton active_pagebtn' : 'paginationbutton'}
            onClick={() => setCurrentDocsPage(i)}
          >
            {i + 1}
          </button>,
        );
      }

      // If endPage is not the last page, add an ellipsis and a button for the last page
      if (endPage < pages) {
        buttons.push(<span className="points-divider-search">...</span>);
        buttons.push(
          <button
            className={currentDocsPage === pages - 1 ? 'paginationbutton active_pagebtn' : 'paginationbutton'}
            onClick={() => setCurrentDocsPage(pages - 1)}
          >
            {pages}
          </button>,
        );
      }
    }

    setbtnsdocs(buttons);
  }

  function returndocs() {
    if (finaldops) {
      return (
        <>
          <div className="ServiceSearch-Downloads-inner">
            {finaldops &&
              finaldops.map((item, index) => {
                if (index >= pagelimitbotdocs && index <= pagelimittopdocs) {
                  return (
                    <>
                      {item.Category != 'Product Performance Sheet' &&
                        item.SearchCategory3 != 'product performance sheet' && (
                          <a
                            className="Downloads-Link"
                            target="_blank"
                            hrefLang={para.count}
                            href={'https://strapiasi3pstore.blob.core.windows.net/downloads-files/' + item.FileName}
                          >
                            <div className={`docs-card ${animate ? 'animateCard' : ''}`}>
                              <span>{item.DisplayName}</span>
                              <span className="Svg-ServiceSearch-icon">
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.25 8.5V12C13.25 12.3315 13.1183 12.6495 12.8839 12.8839C12.6495 13.1183 12.3315 13.25 12 13.25H2C1.66848 13.25 1.35054 13.1183 1.11612 12.8839C0.881696 12.6495 0.75 12.3315 0.75 12V8.5C0.75 8.30109 0.829018 8.11032 0.96967 7.96967C1.11032 7.82902 1.30109 7.75 1.5 7.75C1.69891 7.75 1.88968 7.82902 2.03033 7.96967C2.17098 8.11032 2.25 8.30109 2.25 8.5V11.75H11.75V8.5C11.75 8.30109 11.829 8.11032 11.9697 7.96967C12.1103 7.82902 12.3011 7.75 12.5 7.75C12.6989 7.75 12.8897 7.82902 13.0303 7.96967C13.171 8.11032 13.25 8.30109 13.25 8.5ZM6.46938 9.03063C6.53905 9.10054 6.62185 9.15602 6.71301 9.19388C6.80417 9.23173 6.90191 9.25122 7.00062 9.25122C7.09934 9.25122 7.19707 9.23173 7.28824 9.19388C7.3794 9.15602 7.4622 9.10054 7.53187 9.03063L10.0319 6.53062C10.1728 6.38973 10.2519 6.19863 10.2519 5.99937C10.2519 5.80012 10.1728 5.60902 10.0319 5.46812C9.89098 5.32723 9.69988 5.24807 9.50062 5.24807C9.30137 5.24807 9.11027 5.32723 8.96937 5.46812L7.75 6.6875V1.5C7.75 1.30109 7.67098 1.11032 7.53033 0.96967C7.38968 0.829018 7.19891 0.75 7 0.75C6.80109 0.75 6.61032 0.829018 6.46967 0.96967C6.32902 1.11032 6.25 1.30109 6.25 1.5V6.6875L5.03062 5.46938C4.88973 5.32848 4.69863 5.24932 4.49937 5.24932C4.30012 5.24932 4.10902 5.32848 3.96812 5.46938C3.82723 5.61027 3.74807 5.80137 3.74807 6.00063C3.74807 6.19988 3.82723 6.39098 3.96812 6.53188L6.46938 9.03063Z"
                                    fill="#001020"
                                  />
                                </svg>
                              </span>
                            </div>
                          </a>
                        )}

                      {(item.Category == 'Product Performance Sheet' ||
                        item.SearchCategory3 == 'product performance sheet') && (
                        <a
                          className="Downloads-Link"
                          target="_blank"
                          hrefLang={para.count}
                          href={'https://strapiasi3pstore.blob.core.windows.net/propers/' + item.FileName}
                        >
                          <div className={`docs-card ${animate ? 'animateCard' : ''}`}>
                            <span>{item.DisplayName}</span>
                            <span className="Svg-ServiceSearch-icon">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.25 8.5V12C13.25 12.3315 13.1183 12.6495 12.8839 12.8839C12.6495 13.1183 12.3315 13.25 12 13.25H2C1.66848 13.25 1.35054 13.1183 1.11612 12.8839C0.881696 12.6495 0.75 12.3315 0.75 12V8.5C0.75 8.30109 0.829018 8.11032 0.96967 7.96967C1.11032 7.82902 1.30109 7.75 1.5 7.75C1.69891 7.75 1.88968 7.82902 2.03033 7.96967C2.17098 8.11032 2.25 8.30109 2.25 8.5V11.75H11.75V8.5C11.75 8.30109 11.829 8.11032 11.9697 7.96967C12.1103 7.82902 12.3011 7.75 12.5 7.75C12.6989 7.75 12.8897 7.82902 13.0303 7.96967C13.171 8.11032 13.25 8.30109 13.25 8.5ZM6.46938 9.03063C6.53905 9.10054 6.62185 9.15602 6.71301 9.19388C6.80417 9.23173 6.90191 9.25122 7.00062 9.25122C7.09934 9.25122 7.19707 9.23173 7.28824 9.19388C7.3794 9.15602 7.4622 9.10054 7.53187 9.03063L10.0319 6.53062C10.1728 6.38973 10.2519 6.19863 10.2519 5.99937C10.2519 5.80012 10.1728 5.60902 10.0319 5.46812C9.89098 5.32723 9.69988 5.24807 9.50062 5.24807C9.30137 5.24807 9.11027 5.32723 8.96937 5.46812L7.75 6.6875V1.5C7.75 1.30109 7.67098 1.11032 7.53033 0.96967C7.38968 0.829018 7.19891 0.75 7 0.75C6.80109 0.75 6.61032 0.829018 6.46967 0.96967C6.32902 1.11032 6.25 1.30109 6.25 1.5V6.6875L5.03062 5.46938C4.88973 5.32848 4.69863 5.24932 4.49937 5.24932C4.30012 5.24932 4.10902 5.32848 3.96812 5.46938C3.82723 5.61027 3.74807 5.80137 3.74807 6.00063C3.74807 6.19988 3.82723 6.39098 3.96812 6.53188L6.46938 9.03063Z"
                                  fill="#001020"
                                />
                              </svg>
                            </span>
                          </div>
                        </a>
                      )}
                    </>
                  );
                }
              })}
          </div>
        </>
      );
    }
  }

  return (
    <>
      <div className="Servicesearch-wrap ServiceSearchinclude-wrap" style={{ backgroundColor: '#f2f3f4 !important' }}>
        <div className="Servicesearch-inner">
          <h2>{translatedSpecialText[0]}</h2>
          <div className="Servicesearch-search">
            <label className="ServiceSearch-x-button-label">
              <input
                ref={searchinput}
                onChange={(e) => {
                  setsearchwords(e.target.value);
                }}
                type="text"
                id="searchinput"
                placeholder={para.searchWord ? para.searchWord : translatedTextTypeHere}
              />
              {searchwords.length > 1 && (
                <button
                  id="ServiceSearch-x-button"
                  onClick={() => {
                    setsearchwords('');
                    setfinaldops();
                    document.getElementById('searchinput').value = '';
                  }}
                >
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.854 12.146C12.9005 12.1925 12.9373 12.2476 12.9625 12.3083C12.9876 12.369 13.0006 12.4341 13.0006 12.4998C13.0006 12.5655 12.9876 12.6305 12.9625 12.6912C12.9373 12.7519 12.9005 12.8071 12.854 12.8535C12.8076 12.9 12.7524 12.9368 12.6917 12.962C12.631 12.9871 12.566 13.0001 12.5003 13.0001C12.4346 13.0001 12.3695 12.9871 12.3088 12.962C12.2481 12.9368 12.193 12.9 12.1465 12.8535L8.00028 8.70666L3.85403 12.8535C3.76021 12.9474 3.63296 13.0001 3.50028 13.0001C3.3676 13.0001 3.24035 12.9474 3.14653 12.8535C3.05271 12.7597 3 12.6325 3 12.4998C3 12.3671 3.05271 12.2399 3.14653 12.146L7.2934 7.99979L3.14653 3.85354C3.05271 3.75972 3 3.63247 3 3.49979C3 3.36711 3.05271 3.23986 3.14653 3.14604C3.24035 3.05222 3.3676 2.99951 3.50028 2.99951C3.63296 2.99951 3.76021 3.05222 3.85403 3.14604L8.00028 7.29291L12.1465 3.14604C12.2403 3.05222 12.3676 2.99951 12.5003 2.99951C12.633 2.99951 12.7602 3.05222 12.854 3.14604C12.9478 3.23986 13.0006 3.36711 13.0006 3.49979C13.0006 3.63247 12.9478 3.75972 12.854 3.85354L8.70715 7.99979L12.854 12.146Z"
                      fill="#001020"
                    />
                  </svg>
                </button>
              )}
            </label>
            <button
              onClick={() => {
                if (document.querySelector('.servicesearchcard')) {
                  document
                    .querySelectorAll('.servicesearchcard')
                    [
                      document.querySelectorAll('.servicesearchcard').length - 1
                    ].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
              }}
            >
              {pagetranslations.data.length > 0
                ? pagetranslations.data[0].attributes.FreeData1.ServiceSearch[1]
                : 'Search'}
            </button>
          </div>

          <div className="Servicesearch-results"></div>
        </div>
      </div>
      {arethereresults.length < 1 && (
        <>
          <h3 style={{ textAlign: 'center', marginTop: '24px' }}>
            {pagetranslations.data.length > 0
              ? pagetranslations.data[0].attributes.FreeData1.ServiceSearch[9]
              : 'No results found'}
          </h3>
        </>
      )}
      <div
        className="Servicesearch-table-wrap"
        id="ServiceGeneralSearchOutput"
        style={finaldops && { paddingBottom: '80px' }}
      >
        <div className="Servicesearch-Downloads">
          {returndocs()}

          {docshits > 12 && finaldops && (
            <div className="paginationproducts">
              <button
                onClick={() => {
                  currentDocsPage != 0 && setCurrentDocsPage(currentDocsPage - 1);
                }}
              >
                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.53016 10.4701C6.67106 10.6109 6.75021 10.802 6.75021 11.0013C6.75021 11.2006 6.67106 11.3917 6.53016 11.5326C6.38927 11.6734 6.19817 11.7526 5.99891 11.7526C5.79965 11.7526 5.60856 11.6734 5.46766 11.5326L0.467661 6.53255C0.397741 6.46287 0.342264 6.38008 0.30441 6.28891C0.266556 6.19775 0.24707 6.10001 0.24707 6.0013C0.24707 5.90259 0.266556 5.80485 0.30441 5.71369C0.342264 5.62252 0.397741 5.53973 0.467661 5.47005L5.46766 0.470051C5.60856 0.329155 5.79965 0.25 5.99891 0.25C6.19817 0.25 6.38927 0.329155 6.53016 0.470051C6.67106 0.610947 6.75021 0.802043 6.75021 1.0013C6.75021 1.20056 6.67106 1.39165 6.53016 1.53255L2.06204 6.00068L6.53016 10.4701Z"
                    fill="black"
                  />
                </svg>
              </button>
              {btnsdocs.map((button) => {
                return button;
              })}

              <button
                onClick={() => {
                  currentDocsPage * 12 + 12 < docshits && setCurrentDocsPage(currentDocsPage + 1);
                }}
              >
                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.5306 6.5306L1.5306 11.5306C1.3897 11.6715 1.19861 11.7506 0.999348 11.7506C0.80009 11.7506 0.608994 11.6715 0.468098 11.5306C0.327201 11.3897 0.248047 11.1986 0.248047 10.9993C0.248047 10.8001 0.327201 10.609 0.468098 10.4681L4.93747 5.99997L0.469348 1.5306C0.399583 1.46083 0.344243 1.37801 0.306486 1.28686C0.26873 1.19571 0.249297 1.09801 0.249297 0.999348C0.249297 0.900686 0.26873 0.802989 0.306486 0.711837C0.344243 0.620685 0.399583 0.537863 0.469348 0.468098C0.539113 0.398333 0.621936 0.342993 0.713088 0.305236C0.80424 0.26748 0.901936 0.248047 1.0006 0.248047C1.09926 0.248047 1.19696 0.26748 1.28811 0.305236C1.37926 0.342993 1.46208 0.398333 1.53185 0.468098L6.53185 5.4681C6.60169 5.53786 6.65707 5.62072 6.69481 5.71193C6.73256 5.80313 6.75193 5.9009 6.75181 5.99961C6.7517 6.09832 6.7321 6.19603 6.69414 6.28715C6.65617 6.37827 6.6006 6.461 6.5306 6.5306Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ServiceGeneralSearch;
