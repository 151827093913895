import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './AboutBlock.css';

function AboutBlock() {
  const location = useLocation();
  let para = useParams();
  let [systemarr, setsystemarr] = useState({ data: [] });
  let arrla;
  let la;
  let arrla1;

  const translations = {
    'da-dk': 'Karriere',
    'de-at': 'Karriere',
    'de-ch': 'Karriere',
    'de-de': 'Karriere',
    'x-default': 'Career',
    'en-ca': 'Career',
    'en-gb': 'Career',
    'en-ie': 'Career',
    'en-us': 'Career',
    es: 'Carrera',
    'es-es': 'Carrera',
    'es-us': 'Carrera',
    'fi-fi': 'Ura',
    'fr-be': 'Carrière',
    'fr-ca': 'Carrière',
    'fr-ch': 'Carrière',
    'fr-lu': 'Carrière',
    'it-ch': 'Carriera',
    'it-it': 'Carriera',
    'nb-no': 'Karriere',
    'nl-nl': 'Carrière',
    'nl-be': 'Carrière',
    'pl-pl': 'Kariera',
    'pt-br': 'Carreira',
    'pt-pt': 'Carreira',
    'sv-se': 'Karriär',
  };
  const locale = para.count;
  const translatedTextTypeHere = translations[locale] || translations['x-default'];

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  if (para.count) {
    la = para.count;
    if (la.match('-')) {
      arrla = la.split('-');
      if (arrla[1]) {
        arrla[1] = arrla[1].toUpperCase();
        arrla1 = arrla[0] + '-' + arrla[1];
      }
    } else {
      arrla1 = para.count;
    }
  } else {
    la = 'en';
  }

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$contains]=About-page',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setsystemarr(json);
      })
      .then(() => {
        returnitemstop();
        returnitemsmiddle();
      });
  }, []);
  function returnitemstop() {
    if (systemarr.data.length > 0) {
      return (
        <>
          <h2>{systemarr.data[0].attributes.FreeTitle1}</h2>
          <p>{systemarr.data[0].attributes.FreeText1}</p>
        </>
      );
    }
  }
  function returnitemsmiddle() {
    if (systemarr.data.length > 0) {
      return (
        <>
          <div className="AboutBlock-cards">
            {systemarr.data[0].attributes.FreeData1.data.map((item, index) => {
              return (
                <>
                  <div className="About-card" id={'AboutBlock-Abschnitt' + index}>
                    <div>
                      <h3>{item[0]}</h3>
                    </div>
                    <div className="AboutBlock-texts">
                      {item[1].map((item) => {
                        return (
                          <>
                            {!item.match('<b') && <p className="AboutBlock-text">{item}</p>}
                            {item.match('<b') && <p dangerouslySetInnerHTML={{ __html: item }} />}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </>
      );
    }
  }

  return (
    <>
      <div className="AboutBlock-Head-Wrap">
        <div className="AboutBlock-Head">
          {returnitemstop()}
          <a className="AboutBlock-careerbutton responsiveAboutBtn" href={'/' + para.count + '/career/'}>
            {translatedTextTypeHere}
          </a>
        </div>
      </div>
      <div className="AboutBlock-scroll-buttons-wrap">
        <div className="AboutBlock-scroll-buttons">
          <div>
            <ul>
              <li>
                <button
                  onClick={() => {
                    document
                      .getElementById('AboutBlock-Abschnitt0')
                      .scrollIntoView({ behavior: 'smooth', block: 'center' }, true);
                  }}
                >
                  {systemarr.data.length > 0 && systemarr.data[0].attributes.FreeData1.data[0][0]}
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    document
                      .getElementById('AboutBlock-Abschnitt1')
                      .scrollIntoView({ behavior: 'smooth', block: 'center' }, true);
                  }}
                >
                  {systemarr.data.length > 0 && systemarr.data[0].attributes.FreeData1.data[1][0]}
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    document
                      .getElementById('AboutBlock-Abschnitt2')
                      .scrollIntoView({ behavior: 'smooth', block: 'center' }, true);
                  }}
                >
                  {systemarr.data.length > 0 && systemarr.data[0].attributes.FreeData1.data[2][0]}
                </button>
              </li>
              {systemarr.data.length > 0 && systemarr.data[0].attributes.FreeData1.data.length > 3 && (
                <li>
                  <button
                    onClick={() => {
                      document
                        .getElementById('AboutBlock-Abschnitt3')
                        .scrollIntoView({ behavior: 'smooth', block: 'center' }, true);
                    }}
                  >
                    {systemarr.data.length > 0 && systemarr.data[0].attributes.FreeData1.data[3][0]}
                  </button>
                </li>
              )}
              {systemarr.data.length > 0 && systemarr.data[0].attributes.FreeData1.data.length > 4 && (
                <li>
                  <button
                    onClick={() => {
                      document
                        .getElementById('AboutBlock-Abschnitt4')
                        .scrollIntoView({ behavior: 'smooth', block: 'center' }, true);
                    }}
                  >
                    {systemarr.data.length > 0 && systemarr.data[0].attributes.FreeData1.data[4][0]}
                  </button>
                </li>
              )}
              {systemarr.data.length > 0 && systemarr.data[0].attributes.FreeData1.data.length > 5 && (
                <li>
                  <button
                    onClick={() => {
                      document
                        .getElementById('AboutBlock-Abschnitt5')
                        .scrollIntoView({ behavior: 'smooth', block: 'center' }, true);
                    }}
                  >
                    {systemarr.data.length > 0 && systemarr.data[0].attributes.FreeData1.data[5][0]}
                  </button>
                </li>
              )}
            </ul>
          </div>
          <a className="AboutBlock-careerbutton" href={'/' + para.count + '/career/'}>
            {translatedTextTypeHere}
          </a>
        </div>
      </div>
      <div className="AboutBlock-wrap">
        <div className="AboutBlock-inner">{returnitemsmiddle()}</div>
      </div>
    </>
  );
}

export default AboutBlock;
