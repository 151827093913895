import React, { useEffect, useState } from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './SwiperSlider.css';
import '../Modernslider.css';
import { Link, useParams } from 'react-router-dom';
import LinkData from './Links.json';

function SwiperSlider(props) {
  let [linkarr, setlinkarr] = useState([]);
  let [PageTranslations, setPageTranslations] = useState({ data: [] });
  let [systemarr, setsystemarr] = useState({ data: [] });
  let la;
  let arrla;
  let arrla1;
  let param = useParams();

  function decidethepaths() {
    if (LinkData) {
      let val = ['en', 'en'];
      if (param.count) {
        val = [param.count, 'en'];
        if (param.count.match('-')) {
          val = param.count.split('-');
        }
      }
      if (param.count == 'es') {
        val = ['es', 'es'];
      }

      switch (val[0]) {
        case 'de':
          setlinkarr(LinkData.data.de_arr);
          break;
        case 'da':
          setlinkarr(LinkData.data.da_arr);
          break;
        case 'en':
          setlinkarr(LinkData.data.globallangarr);
          break;
        case 'it':
          setlinkarr(LinkData.data.it_arr);
          break;
        case 'es':
          setlinkarr(LinkData.data.es_arr);
          break;
        case 'fi':
          setlinkarr(LinkData.data.fi_arr);
          break;
        case 'fr':
          setlinkarr(LinkData.data.fr_arr);
          break;
        case 'nb':
          setlinkarr(LinkData.data.nb_arr);
          break;
        case 'nl':
          setlinkarr(LinkData.data.nl_arr);
          break;
        case 'pl':
          setlinkarr(LinkData.data.pl_arr);
          break;
        case 'pt':
          setlinkarr(LinkData.data.pt_arr);
          break;
        case 'sv':
          setlinkarr(LinkData.data.sv_arr);
          break;
        case 'tr':
          setlinkarr(LinkData.data.tr_arr);
          break;

        default:
          setlinkarr(LinkData.data.en_arr);
          break;
      }
      if (val[0] == 'en' && val[1] == 'us') {
        setlinkarr(LinkData.data.en_us_arr);
      }
      if (val[0] == 'en' && val[1] == 'ca') {
        setlinkarr(LinkData.data.en_us_arr);
      }
    }
  }
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    decidethepaths();

    if (!props.gelaenderCat && !props.SystemTopCat) {
      const cachedData = localStorage.getItem(param.count + '-cachedDataSystemSlider');
      if (cachedData && !props.url) {
        let now = new Date();
        setsystemarr(JSON.parse(cachedData).json);
        if (now.getTime() > JSON.parse(cachedData).expiry) {
          localStorage.removeItem(param.count + '-cachedDataSystemSlider');
        }
      } else {
        let now = new Date();
        fetch(
          'https://strapi-qrailing.azurewebsites.net/api/systems?locale=' +
            arrla1 +
            '&populate=CardImage3x2&sort=Priority:desc&pagination[pageSize]=50',
          requestOptions,
        )
          .then((res) => res.json())
          .then((json) => {
            setsystemarr(json);
            localStorage.setItem(
              param.count + '-cachedDataSystemSlider',
              JSON.stringify({ json, expiry: now.getTime() + 24 * 60 * 60 * 1000 }),
            );
          });
      }

      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
          arrla1 +
          '&filters[Slug][$eq]=SystemSlider',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setPageTranslations(json);
        });
    }
    if (props.SystemTopCat) {
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/systems?locale=' +
          arrla1 +
          '&populate=CardImage3x2&sort=Priority:desc&pagination[pageSize]=50&filters[TopSystem][$eq]=true',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setsystemarr(json);
        });

      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
          arrla1 +
          '&filters[Slug][$eq]=SystemSlider',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setPageTranslations(json);
        });
    }

    if (props.gelaenderCat) {
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/systems?locale=' +
          arrla1 +
          '&populate=CardImage3x2&sort=Priority:desc&filters[' +
          props.topic +
          '][$eq]=true',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setsystemarr(json);
        });
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
          arrla1 +
          '&filters[Slug][$eq]=SystemSlider',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setPageTranslations(json);
        });
    }
  }, []);

  useEffect(() => {
    if (props.color == 'grey' && PageTranslations.data.length > 0) {
      if (document.querySelector('.SwiperSlider-wrap')) {
        document.querySelector('.SwiperSlider-wrap').style = 'background-color:#f2f3f4 !important;';
        document.getElementById('Sliderprevbtn10').style = 'background-color:white !important;';
        document.getElementById('Sliderprevbtn11').style = 'background-color:white !important;';
      }
    }
  }, [PageTranslations]);

  const breakpoints = {
    // When window width is >= 768px
    1024: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 2,
    },
    // When window width is >= 576px
    576: {
      slidesPerView: 2,
    },
    // When window width is >= 320px
    320: {
      slidesPerView: 1,
    },
    100: {
      slidesPerView: 1,
    },
  };

  return (
    <>
      <div id="systemslider-jump"></div>
      {PageTranslations.data.length > 0 && (
        <div className="SwiperSlider-wrap">
          <div className="SwiperSlider-inner">
            <div className="Systems-slider-top-inner">
              <div className="Systems-slider-top-header">
                <h2>
                  {!props.gelaenderCat && (
                    <>
                      {PageTranslations.data.length > 0
                        ? PageTranslations.data[0].attributes.PageTranslations.data[0]
                        : 'Our systems'}
                    </>
                  )}
                  {props.gelaenderCat && props.dynamictext.Slidertranslations[0]}
                </h2>
              </div>
              <div className="Systems-slider-top-text SwiperSlider-text">
                <div>
                  <p>
                    {!props.gelaenderCat && (
                      <>
                        {PageTranslations.data.length > 0
                          ? PageTranslations.data[0].attributes.PageTranslations.data[1]
                          : 'Countless balustrades and handrails have been installed around the world. Every conceivable type of construction has been undertaken'}
                      </>
                    )}
                    {props.gelaenderCat && props.dynamictext.Slidertranslations[1]}
                  </p>
                </div>
                <div>
                  <button
                    className={systemarr.data.length < 4 ? 'hiddenSliderBtn' : ''}
                    id="Sliderprevbtn10"
                    aria-label="prev-btn-systems"
                    onClick={() => {
                      if (document.querySelector('.swiper-button-prev')) {
                        document.querySelector('.swiper-button-prev').click();
                      }
                    }}
                  >
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.0306 6.53074L8.53063 11.0307C8.38973 11.1716 8.19863 11.2508 7.99938 11.2508C7.80012 11.2508 7.60902 11.1716 7.46813 11.0307C7.32723 10.8898 7.24807 10.6987 7.24807 10.4995C7.24807 10.3002 7.32723 10.1091 7.46813 9.96824L10.6875 6.75011H1.5C1.30109 6.75011 1.11032 6.67109 0.96967 6.53044C0.829018 6.38979 0.75 6.19902 0.75 6.00011C0.75 5.8012 0.829018 5.61043 0.96967 5.46978C1.11032 5.32913 1.30109 5.25011 1.5 5.25011H10.6875L7.46937 2.03011C7.32848 1.88921 7.24932 1.69812 7.24932 1.49886C7.24932 1.2996 7.32848 1.10851 7.46937 0.967609C7.61027 0.826713 7.80137 0.747559 8.00062 0.747559C8.19988 0.747559 8.39098 0.826713 8.53187 0.967609L13.0319 5.46761C13.1018 5.53738 13.1573 5.62028 13.1951 5.71155C13.2329 5.80281 13.2523 5.90065 13.2522 5.99943C13.252 6.09822 13.2324 6.19601 13.1944 6.28718C13.1564 6.37836 13.1007 6.46113 13.0306 6.53074Z"
                        fill="#001020"
                      />
                    </svg>
                  </button>

                  <button
                    className={systemarr.data.length < 4 ? 'hiddenSliderBtn' : ''}
                    id="Sliderprevbtn11"
                    aria-label="back-btn-systems"
                    onClick={() => {
                      if (document.querySelector('.swiper-button-next')) {
                        document.querySelector('.swiper-button-next').click();
                      }
                    }}
                  >
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.0306 6.53074L8.53063 11.0307C8.38973 11.1716 8.19863 11.2508 7.99938 11.2508C7.80012 11.2508 7.60902 11.1716 7.46813 11.0307C7.32723 10.8898 7.24807 10.6987 7.24807 10.4995C7.24807 10.3002 7.32723 10.1091 7.46813 9.96824L10.6875 6.75011H1.5C1.30109 6.75011 1.11032 6.67109 0.96967 6.53044C0.829018 6.38979 0.75 6.19902 0.75 6.00011C0.75 5.8012 0.829018 5.61043 0.96967 5.46978C1.11032 5.32913 1.30109 5.25011 1.5 5.25011H10.6875L7.46937 2.03011C7.32848 1.88921 7.24932 1.69812 7.24932 1.49886C7.24932 1.2996 7.32848 1.10851 7.46937 0.967609C7.61027 0.826713 7.80137 0.747559 8.00062 0.747559C8.19988 0.747559 8.39098 0.826713 8.53187 0.967609L13.0319 5.46761C13.1018 5.53738 13.1573 5.62028 13.1951 5.71155C13.2329 5.80281 13.2523 5.90065 13.2522 5.99943C13.252 6.09822 13.2324 6.19601 13.1944 6.28718C13.1564 6.37836 13.1007 6.46113 13.0306 6.53074Z"
                        fill="#001020"
                      />
                    </svg>
                  </button>

                  <Link to={'/' + param.count + linkarr[8]}>
                    {PageTranslations.data.length > 0
                      ? PageTranslations.data[0].attributes.PageTranslations.data[2]
                      : 'View all systems'}
                  </Link>
                </div>
              </div>
            </div>
            <Swiper
              id="swiperr"
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={24}
              slidesPerView={3}
              navigation
              pagination={false}
              scrollbar={{ draggable: true }}
              breakpoints={breakpoints}
            >
              {systemarr.data.length > 0 &&
                systemarr.data.map((item, index) => {
                  return (
                    <SwiperSlide key={'Systemcard' + index}>
                      <div className="product-card product-card2 product-card-system" id="product-card">
                        <Link hrefLang={param.count} to={'/' + param.count + linkarr[8] + item.attributes.Slug + '/'}>
                          <img
                            className="systemimageslider"
                            src={item.attributes.CardImage3x2.data && item.attributes.CardImage3x2.data.attributes.url}
                            alt={'SystemCard' + index}
                            loading="lazy"
                            width="300"
                            height={200}
                          />
                          <h3 className="systemsliderSystemspan">{item.attributes.Title}</h3>
                          <p className="systemsliderSystemtext">
                            {item.attributes.HeaderText && item.attributes.HeaderText.data[1]}
                          </p>
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      )}
    </>
  );
}

export default SwiperSlider;
