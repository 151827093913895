import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocale } from 'hooks/use-locale';
import { LoadingScreen } from 'components/loading-screen/loading-screen';

export const BaseRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locale = useLocale();
  console.log('baserouter');
  useEffect(() => {
    const redirect = (route) => {
      if (!route.endsWith('/')) route += '/';
      console.log('redirecting to', route);
      // remove current link from history to prevent back button from redirecting to the same link
      navigate(route, { replace: true });
    };

    if (!locale) return;
    // get the current and put in format this/is/a/route
    let currentEndpoint = location.pathname
      .split('/')
      .filter((x) => x && x !== '')
      .join('/');
    redirect(
      `/${locale}/${currentEndpoint && currentEndpoint !== '' ? currentEndpoint + '/' : ''}${location.search}${location.hash}`,
    );
  }, [locale, navigate]);

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <LoadingScreen scale={1} />
    </div>
  );
};
