import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Catalogue.css';

function Catalogue(props) {
  let para = useParams();
  let [wordarr, setwordarr] = useState({ data: [] });

  let param = useParams();

  let la;

  let arrla;
  let arrla1 = '';
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    if (para.count == 'de-de' && props.type == 'architect') {
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
          arrla1 +
          '&filters[Slug][$eq]=CatalogArchitect&populate=BannerImage',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setwordarr(json);
        });
    } else {
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
          arrla1 +
          '&filters[Slug][$eq]=CatalogPage&populate=BannerImage',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setwordarr(json);
        });
    }
  }, []);
  return (
    <>
      {wordarr.data.length > 0 && (
        <div className={'Catalogue-wrap'} id={props.color == 'white' && 'whiteCatalogWrap'}>
          <div className="Catalogue-inner">
            <div className="Catalogue-blöcke">
              <div className="Catalogue-textside">
                <h2>{wordarr.data[0].attributes.FreeTitle1}</h2>
                <p>{wordarr.data[0].attributes.FreeText1}</p>
                <div className="Cataloge-view-buttons">
                  <a target="_blank" href={wordarr.data[0].attributes.FreeData1.data[1][1]}>
                    {wordarr.data[0].attributes.FreeData1.data[1][0]}
                  </a>{' '}
                  <a target="_blank" href={wordarr.data[0].attributes.FreeData1.data[0][1]}>
                    {wordarr.data[0].attributes.FreeData1.data[0][0]}
                  </a>
                </div>
              </div>
              <div className="Catalogue-image">
                <img width={424} src={wordarr.data[0].attributes.BannerImage.data.attributes.url} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Catalogue;
