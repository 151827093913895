import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function SystemHeadline(props) {
  let la;
  let arrla;
  let [systemarr, setsystemarr] = useState({ data: [] });
  let param = useParams();
  let arrla1 = '';
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    if (!props.type) {
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
          arrla1 +
          '&filters[Slug][$eq]=SystemsHeadlineBlock',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setsystemarr(json);
        })
        .then(() => returnHeadText());
    }
  }, []);

  function returnHeadText() {}
  return (
    <>
      <div className="SystemHeadline-wrap">
        <div className="SystemHeadline-inner">
          <div className="SystemHeadline-text">
            {props.type != 'Installer' && (
              <>
                <h2>{systemarr.data.length > 0 ? systemarr.data[0].attributes.FreeTitle1 : 'Systems'}</h2>
                <p>
                  {systemarr.data.length > 0
                    ? systemarr.data[0].attributes.FreeText1
                    : 'Our Systems for your next project'}
                </p>
              </>
            )}

            {props.type == 'Installer' && (
              <>
                <h2>{props.title1}</h2>
                <p>{props.text1}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SystemHeadline;
