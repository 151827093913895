import React from 'react';
import HeaderSlim from './includes/HeaderSlim';
import Servicesearch from './includes/Servicesearch';
import { useParams } from 'react-router-dom';
import Footernew from './includes/Footernew';
import NeedHelp from './includes/NeedHelp';
import { Helmet } from 'react-helmet';

function Downloads() {
  let param = useParams();

  return (
    <>
      <Helmet>
        <title>Downloads</title>
        <meta name="description" content="Q-railing downloads"></meta>
      </Helmet>
      <HeaderSlim key={param.dllcat}></HeaderSlim>
      <Servicesearch filter={'nofilter'}></Servicesearch>
      <NeedHelp color="white"></NeedHelp>
      <Footernew></Footernew>
    </>
  );
}

export default Downloads;
