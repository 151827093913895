import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Headerprojects from './includes/Headerprojects';
import Footernew from './includes/Footernew';
import TwoColBlock from './includes/TwoColBlock';
import ThreeCardGrid from './includes/ThreeCardGrid';
import NeedHelp from './includes/NeedHelp';
import VideoList from './includes/VideoList';
import './SafetyPage.css';
import { Helmet } from 'react-helmet';
import AboutBlock from './includes/AboutBlock';
function Aboutpage() {
  //eslint-disable-next-line
  let [systemarr, setsystemarr] = useState({ data: [] });
  let la;
  let arrla;
  let param = useParams();
  let [dataa, setdataa] = useState({ data: [] });

  let arrla1 = '';

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/landingpages?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=safety&populate=BannerImage',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setsystemarr(json);
        setdataa(json);
        console.log(json);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{dataa.data.length > 0 ? dataa.data[0].attributes.MetaTitle : 'Safety'}</title>
        <meta
          name="description"
          content={dataa.data.length > 0 ? dataa.data[0].attributes.MetaText : 'Q-railing on tour'}
        ></meta>
      </Helmet>

      {dataa.data.length > 0 && <Headerprojects key={'safety'} dater={dataa} seotopic={'safety'}></Headerprojects>}

      {dataa.data.length > 0 && (
        <TwoColBlock PutTopLeft inheritance inheritanceData={dataa} text={'FirstBlock'}></TwoColBlock>
      )}
      {dataa.data.length > 0 && <AboutBlock></AboutBlock>}
      {dataa.data.length > 0 && <ThreeCardGrid dater={dataa} color={'grey'}></ThreeCardGrid>}

      {dataa.data.length > 0 && <TwoColBlock inheritance inheritanceData={dataa} text={'SecondBlock'}></TwoColBlock>}
      {dataa.data.length > 0 && (
        <TwoColBlock inheritance color={'grey'} inheritanceData={dataa} text={'ThirdBlock'}></TwoColBlock>
      )}
      {dataa.data.length > 0 && (
        <div className="VideoList-Headlineblock-wrap">
          <div className="VideoList-Headlineblock-inner">
            <h2>{dataa.data[0].attributes.MultipleSeoTexts.TextBlockVideos[0]}</h2>
            <p>
              <b>{dataa.data[0].attributes.MultipleSeoTexts.TextBlockVideos[1]}</b>
            </p>
            <p>{dataa.data[0].attributes.MultipleSeoTexts.TextBlockVideos[2]}</p>
          </div>
        </div>
      )}
      {dataa.data.length > 0 && <VideoList dater={dataa}></VideoList>}
      {dataa.data.length > 0 && <ThreeCardGrid color={'grey'} dater={dataa} mode={4}></ThreeCardGrid>}

      {dataa.data.length > 0 && <TwoColBlock inheritance inheritanceData={dataa} text={'FourthBlock'}></TwoColBlock>}

      {dataa.data.length > 0 && (
        <TwoColBlock inheritance inheritanceData={dataa} color={'grey'} text={'FifthBlock'}></TwoColBlock>
      )}
      {dataa.data.length > 0 && <NeedHelp></NeedHelp>}

      <Footernew></Footernew>
    </>
  );
}

export default Aboutpage;
