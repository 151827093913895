import React from 'react';

function Qsupport() {
  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          backgroundColor: '#f2f3f4',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ position: 'fixed', top: '0' }}>
          <a style={{ display: 'flex', alignItems: 'center', gap: '24px', color: '#001020', width: '1096px' }} href="/">
            <img
              width={72}
              height={72}
              src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/q-logo.png"
            />{' '}
            Back to Mainpage
          </a>
        </div>

        <div className="Qsupport" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1>Download the Q-railing support file</h1>
          <br />
          <p style={{ color: '#667079' }}>Click the button to download the Q-railing IT support file</p>
          <br />
          <br />
          <br />
          <a
            style={{ backgroundColor: '#005094', borderRadius: '24px', padding: '16px 32px', color: 'white' }}
            href="https://strapiwmc4hstore.blob.core.windows.net/website-scripts/help.exe"
          >
            Download support file
          </a>
        </div>
      </div>
    </>
  );
}

export default Qsupport;
