import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './TwoColBlock.css';
function TwoColBlock(props) {
  let arrla;

  let arrla1;
  let la;
  let param = useParams();

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let [apiData, setApiData] = useState({ data: [] });
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    if (!props.inheritance) {
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
          arrla1 +
          '&filters[Slug][$eq]=TwoColumnBlock',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setApiData(json);
        });
    } else {
      setApiData(props.inheritanceData);
    }
  }, []);

  return (
    <>
      {apiData.data.length > 0 && (
        <div
          className={!props.firstblock ? 'TwoColBlock-wrap' : 'TwoColBlock-wrap TwoColBlock-wrap-first'}
          id={props.color == 'grey' && 'TwoColBlockGrey'}
        >
          <div className="TwoColBlock-inner">
            <div className="TwoColBlock-left-wrap" id={props.PutTopLeft && 'TwoColBlock-top'}>
              <div
                className="TwoColBlock-left"
                dangerouslySetInnerHTML={{ __html: apiData.data[0].attributes.FreeData1[props.text].Left }}
              ></div>
            </div>
            <div className="TwoColBlock-right-wrap" id={props.PutTopRight && 'TwoColBlock-top'}>
              <div
                className="TwoColBlock-right"
                dangerouslySetInnerHTML={{ __html: apiData.data[0].attributes.FreeData1[props.text].Right }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TwoColBlock;
