import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
function RecentProjects(props) {
  let [translations, settranslations] = useState({ data: [] });
  let [returnproj, setreturnproj] = useState([]);
  useEffect(() => {
    if (props.color) {
      if (props.color == 'grey') {
        document.querySelector('.Recentprojects-wrap').style = 'background-color: #f2f3f4 !important;';
      }
    }
  }, []);
  let para = useParams();
  let param = useParams();
  let la;
  let arrla;
  let arrla1 = '';
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    const cachedData = localStorage.getItem(para.count + '-cachedDataRecentProjects');
    if (cachedData) {
      const now = new Date();
      // compare the expiry time of the item with the current time
      if (now.getTime() > JSON.parse(cachedData).expiry) {
        localStorage.removeItem(para.count + '-cachedDataRecentProjects');
      }
      returnprojects(JSON.parse(cachedData).json);
    } else {
      let now = new Date();
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/projects?locale=' +
          arrla1 +
          '&filters[PriorityProject][$contains]=PriorityProject&populate=RecentProjectsImage',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          localStorage.setItem(
            para.count + '-cachedDataRecentProjects',
            JSON.stringify({ json, expiry: now.getTime() + 24 * 60 * 60 * 1000 }),
          );
          returnprojects(json);
        });
    }

    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=RecentProjectsText',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        settranslations(json);
      });
  }, []);

  function returnprojects(propss) {
    if (propss) {
      let arraa = [];
      let count = 4;
      if (propss.data.length < 4) {
        count = propss.data.length;
      }
      for (let index = 0; index < count; index++) {
        arraa.push(propss.data[index]);
      }
      setreturnproj(arraa);
    }
  }

  return (
    <>
      {returnproj && (
        <div className="Recentprojects-wrap">
          <div className="Recentprojects-inner">
            <div className="Recentprojects-head-wrap">
              <div className="Recentprojects-head">
                <h2>{translations.data.length > 0 && translations.data[0].attributes.FreeTitle1}</h2>
                <p>{translations.data.length > 0 && translations.data[0].attributes.FreeText1}</p>
              </div>
              <div className="Recentprojects-head-link">
                <Link to={'/' + para.count + '/inspiration/'}>
                  {' '}
                  {translations.data.length > 0 && translations.data[0].attributes.FreeTitle2}
                </Link>
              </div>
            </div>
            <div className="Recentprojects-projects">
              {returnproj &&
                returnproj.map((item, index) => {
                  if (item) {
                    return (
                      <div key={'mapkey' + index} className="Recentprojects-project-card">
                        <Link hrefLang={para.count} to={'/' + para.count + '/projects/' + item.attributes.Slug + '/'}>
                          <img
                            alt={'recent project image' + index}
                            src={
                              item.attributes.RecentProjectsImage &&
                              item.attributes.RecentProjectsImage.data.attributes.url
                            }
                            width="536"
                            height="296"
                          />
                          <p>{item.attributes.Title}</p>
                        </Link>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      )}
      {!returnproj && <div id="RecentProjects-shadowdiv"></div>}
    </>
  );
}

export default RecentProjects;
