import React, { useEffect, useState } from 'react';

import './GeneralCss.css';
import './Contenttext.css';
import { useParams } from 'react-router-dom';

function Contenttext(props) {
  let [wordarr, setwordarr] = useState({ data: [] });
  let param = useParams();

  let idclass = 'basestyleforhtmlinject';
  if (props.text) {
    document.getElementById('api-data').innerHTML = props.text.data[0].attributes.CustomText;
  }
  if (props.idclass) {
    if (props.idclass == 'ordering-textpage') {
      idclass = 'ordering-textpage';
    }
    if (props.idclass == 'payment-textpage') {
      idclass = 'payment-textpage';
    }
    if (props.idclass == 'shipping-textpage') {
      idclass = 'shipping-textpage';
    }
    if (props.idclass == 'returns-textpage') {
      idclass = 'returns-textpage';
    }
  }

  useEffect(() => {
    if (props.architect) {
      if (document.querySelector('.Contenttext-inner-inner')) {
        document.querySelector('.Contenttext-inner-inner').style = 'margin-top:0px';
      }
      let la;
      idclass = 'architects-textpage';
      let arrla;
      let arrla1 = '';
      if (param.count) {
        la = param.count;
        arrla = la.split('-');
        if (param.count == 'en') {
          arrla = ['en', 'INTERNATIONAL'];
        }
        if (param.count == 'es') {
          arrla = ['es', 'INTERNATIONAL'];
        }
        if (arrla[1]) {
          arrla[1] = arrla[1].toUpperCase();
          arrla1 = arrla[0] + '-' + arrla[1];
          if (arrla[1] == 'INTERNATIONAL') {
            arrla1 = arrla[0];
          }
        }
      } else {
        arrla1 = 'en';
      }
      let token = process.env.REACT_APP_PUBLIC_KEY2;
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      };

      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
          arrla1 +
          '&filters[Slug][$eq]=ArchitectsTextInclude',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setwordarr(json);
          console.log(json);
        });
    }
  }, []);

  useEffect(() => {
    if (wordarr.data.length > 0) {
      document.getElementById('api-data').innerHTML = wordarr.data[0].attributes.CustomText;
    }
  }, [wordarr]);

  return (
    <>
      {!props.architect && (
        <div className="Contenttext-wrap-head ">
          <div className="Contenttext-inner">{props.text && <h2>{props.text.data[0].attributes.Title}</h2>}</div>
        </div>
      )}
      <div className="Contenttext-wrap" id={props.architect ? 'architects-textpage' : idclass}>
        <div className="Contenttext-inner">
          <div className="Contenttext-inner-inner">
            <div id="api-data"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contenttext;
