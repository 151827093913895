import { useLocale } from 'hooks/use-locale';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { translatePathToEnglish, translateRoute } from 'utility/routing/common/routing';

const languages = [
  {
    hreflang: 'da-DK',
    href: 'https://q-railing.com/da-dk/',
  },
  {
    hreflang: 'de-AT',
    href: 'https://q-railing.com/de-at/',
  },
  {
    hreflang: 'de-CH',
    href: 'https://q-railing.com/de-ch/',
  },
  {
    hreflang: 'de-DE',
    href: 'https://q-railing.com/de-de/',
  },
  {
    hreflang: 'x-default',
    href: 'https://q-railing.com/en/',
  },
  {
    hreflang: 'en-CA',
    href: 'https://q-railing.com/en-ca/',
  },
  {
    hreflang: 'en-AT',
    href: 'https://q-railing.com/en-at/',
  },
  {
    hreflang: 'en-GB',
    href: 'https://q-railing.com/en-gb/',
  },
  {
    hreflang: 'en-IE',
    href: 'https://q-railing.com/en-ie/',
  },
  {
    hreflang: 'en-US',
    href: 'https://q-railing.com/en-us/',
  },
  {
    hreflang: 'es',
    href: 'https://q-railing.com/es/',
  },
  {
    hreflang: 'es-ES',
    href: 'https://q-railing.com/es-es/',
  },
  {
    hreflang: 'es-US',
    href: 'https://q-railing.com/es-us/',
  },
  {
    hreflang: 'fi-FI',
    href: 'https://q-railing.com/fi-fi/',
  },
  {
    hreflang: 'fr-BE',
    href: 'https://q-railing.com/fr-be/',
  },
  {
    hreflang: 'fr-CA',
    href: 'https://q-railing.com/fr-ca/',
  },
  {
    hreflang: 'fr-CH',
    href: 'https://q-railing.com/fr-ch/',
  },
  {
    hreflang: 'fr-FR',
    href: 'https://q-railing.com/fr-fr/',
  },
  {
    hreflang: 'fr-LU',
    href: 'https://q-railing.com/fr-lu/',
  },
  {
    hreflang: 'it-CH',
    href: 'https://q-railing.com/it-ch/',
  },
  {
    hreflang: 'it-IT',
    href: 'https://q-railing.com/it-it/',
  },
  {
    hreflang: 'nb-NO',
    href: 'https://q-railing.com/nb-no/',
  },
  {
    hreflang: 'nl-BE',
    href: 'https://q-railing.com/nl-be/',
  },
  {
    hreflang: 'nl-NL',
    href: 'https://q-railing.com/nl-nl/',
  },
  {
    hreflang: 'pl-PL',
    href: 'https://q-railing.com/pl-pl/',
  },
  {
    hreflang: 'pt-BR',
    href: 'https://q-railing.com/pt-br/',
  },
  {
    hreflang: 'pt-PT',
    href: 'https://q-railing.com/pt-pt/',
  },
  {
    hreflang: 'sv-SE',
    href: 'https://q-railing.com/sv-se/',
  },
];

export const MetaLocaleRoutes = () => {
  const locale = useLocale();
  const location = useLocation();
  const [routes, setAlternateRoutes] = useState();

  useEffect(() => {
    const originalPath = location.pathname;

    if (!originalPath || originalPath === '/') return;

    const englishPath = translatePathToEnglish(originalPath, locale);

    const alternates = languages
      .map((language) => {
        const langLocale = language.href.split('/')[3];
        const translated = translateRoute(englishPath, langLocale);

        if (!translated) return undefined;
        const alternateRoute = `${language.href}${translated
          .split('/')
          .slice(2)
          .filter((x) => x.trim() !== '')
          .join('/')}`;
        return {
          hreflang: language.hreflang,
          href: alternateRoute.endsWith('/') ? alternateRoute : alternateRoute + '/',
        };
      })
      .filter((x) => x);

    setAlternateRoutes(alternates);
  }, [location]);

  return (
    <Helmet>
      {routes?.map((alternate) => (
        <link rel="alternate" hreflang={alternate.hreflang} href={alternate.href} />
      ))}
    </Helmet>
  );
};
