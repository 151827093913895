import React, { useEffect, useState } from 'react';

import './VideoList.css';
function VideoList(props) {
  let [apiData, setApiData] = useState({ data: [] });
  useEffect(() => {
    if (props.dater) {
      setApiData(props.dater);
    }
  }, []);
  return (
    <>
      {apiData.data.length > 0 && (
        <div className="VideoList-wrap">
          {apiData.data[0].attributes.FreeData4.VideoBlock.map((item, indexx) => {
            return (
              <div className={'VideoList-innerWrap'} id={indexx % 2 != 0 && 'VideoList-Grey'}>
                <div className="TwoColBlock-inner">
                  <div className="TwoColBlock-left-wrap">
                    <div className="TwoColBlock-left" dangerouslySetInnerHTML={{ __html: item[0] }}></div>
                  </div>
                  <div className="TwoColBlock-right-wrap">
                    <div className="TwoColBlock-right">
                      <h2>{item[1]}</h2>
                      <table className="VideoList-Table">
                        {item.map((itemm, index) => {
                          if (index > 2) {
                            return (
                              <tr>
                                <td>{itemm[0]}</td>
                                <td>{itemm[1]}</td>
                              </tr>
                            );
                          }
                        })}
                      </table>
                      <a href={item[2][0]}>{item[2][1]}</a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default VideoList;
