import React, { useEffect, useState } from 'react';
import Howwework2 from './includes/Howwework2';
import HeaderSlim from './includes/HeaderSlim';
import Footernew from './includes/Footernew';
import { useParams } from 'react-router-dom';
import './InstallerRequest.css';
import NeedHelp from './includes/NeedHelp';
import { Helmet } from 'react-helmet';
import ConfiBlock from './includes/ConfiBlock';
import NewsletterNew from './includes/NewsletterNew';

function InstallerRequest() {
  let [pagedata, setpagedata] = useState({ data: [] });
  let param = useParams();

  let la;

  let arrla;
  let arrla1 = '';
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let fetchurl =
    'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
    arrla1 +
    '&filters[Slug][$eq]=FindAnInstallerData&populate=BannerImage';
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    const cachedData = localStorage.getItem('cachedDataFindAnInstaller');
    if (cachedData) {
      const now = new Date();
      // compare the expiry time of the item with the current time
      setpagedata(JSON.parse(cachedData).json);
      if (now.getTime() > JSON.parse(cachedData).expiry) {
        localStorage.removeItem('cachedDataFindAnInstaller');
      }
    } else {
      let now = new Date();
      fetch(fetchurl, requestOptions)
        .then((res) => res.json())
        .then((json) => {
          setpagedata(json);
          console.log(json);
          localStorage.setItem(
            'cachedDataFindAnInstaller',
            JSON.stringify({ json, expiry: now.getTime() + 24 * 60 * 60 * 1000 }),
          );
        });
    }
  }, []);

  function saveConfig() {
    let LastName = document.getElementById('register-form-last-name').value;
    let Email = document.getElementById('register-form-email').value;
    let City = document.getElementById('register-form-city').value;
    let Phone = document.getElementById('register-form-phone').value;
    let Salutation = document.getElementById('register-form-salutation').value;
    let ConfigNumber = document.getElementById('register-form-config-number').value;

    let PostCode = '';
    let Country = '';
    let Street = '';
    let Comment = '';

    document.getElementById('InstallerRequestError').innerText = '';

    Comment = document.getElementById('register-form-comment').value;
    Country = document.getElementById('register-form-country').value;
    PostCode = document.getElementById('register-form-zipcode').value;
    Street = document.getElementById('register-form-address').value;

    //Normal sanit

    if (Email.length < 1) {
      document.getElementById('InstallerRequestError').innerText = 'please enter your email';
      return;
    }
    if (City.length < 1) {
      document.getElementById('InstallerRequestError').innerText = 'please enter your city';
      return;
    }
    if (PostCode.length < 1) {
      document.getElementById('InstallerRequestError').innerText = 'please enter your postcode';
      return;
    }

    if (Phone.length < 1) {
      document.getElementById('InstallerRequestError').innerText = 'please enter a phone number';
      return;
    }
    if (!Email.match('@') || !Email.match('.')) {
      document.getElementById('InstallerRequestError').innerText = 'please enter a valid email';
      return;
    }

    //specific sanit

    if (LastName.length < 1) {
      return;
    }

    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const timestamp = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    let LocaleByPage = param.count;

    let bodyy = JSON.stringify({
      data: {
        Email: Email,
        LastName: LastName,
        PostCode: PostCode,
        Country: Country,
        City: City,
        Phone: Phone,
        Street: Street,
        Salutation: Salutation,
        Comments: Comment,
        TimeStamp: timestamp,
        ConfigurationCode: ConfigNumber,
        Locale: LocaleByPage,
      },
    });
    //decide where to send those things

    fetchurl = 'https://strapi-qrailing.azurewebsites.net/api/installer-requests/';
    let requestTextOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      body: bodyy,
    };

    fetch(fetchurl, requestTextOptions)
      .then((response) => response.json())
      .then(
        setTimeout(() => {
          console.log('###########sleep#############');
        }, 2000),
      )
      .then((data) => this.setState({ postId: data.id }));

    //reset values to Zero
    document.getElementById('ApplyButton').style = 'display:none';

    document.getElementById('register-form-email').value = '';
    document.getElementById('register-form-city').value = '';
    document.getElementById('register-form-last-name').value = '';
    document.getElementById('register-form-phone').value = '';
  }

  return (
    <>
      {pagedata.data.length > 0 && (
        <Helmet>
          <title>{pagedata.data[0].attributes.MetaTitle}</title>
          <meta name="description" content={pagedata.data[0].attributes.MetaText} />
        </Helmet>
      )}
      <HeaderSlim></HeaderSlim>
      {pagedata.data.length > 0 && (
        <div id="Block404-Banner-Image" className="InstallerRequest-banner">
          {pagedata.data[0].attributes.BannerImage && (
            <img src={pagedata.data[0].attributes.BannerImage.data.attributes.url} />
          )}
          <div id="Block404-Banner-Text-wrap" className="Prefix-BannerText">
            <h2>{pagedata.data[0].attributes.FreeTitle1}</h2>
            <p>{pagedata.data[0].attributes.FreeText1}</p>
          </div>
        </div>
      )}
      <Howwework2 type={'consumer'} color="white"></Howwework2>
      <ConfiBlock color="grey"></ConfiBlock>
      <form onSubmit={(e) => e.preventDefault()} className="InstallerRequest-wrap" id="installer-request-form">
        <div className="InstallerRequest-inner-text">
          <div>
            <h3>{pagedata.data.length > 0 && pagedata.data[0].attributes.FreeTitle3}</h3>
            <p>{pagedata.data.length > 0 && pagedata.data[0].attributes.FreeText2}</p>
          </div>
        </div>

        <div className="InstallerRequest-inner-2">
          <div>
            <h3>{pagedata.data.length > 0 && pagedata.data[0].attributes.FreeTitle2}</h3>
          </div>
        </div>
        <div className="InstallerRequest-inner">
          <div className="Registerform-inputs">
            <label>
              <h4>
                {pagedata.data.length > 0 && pagedata.data[0].attributes.FreeData1.data[10]}
                <span>{pagedata.data.length > 0 ? pagedata.data[0].attributes.FreeData1.data[15] : '(optional)'}</span>
              </h4>
              <select id="register-form-salutation">
                {pagedata.data.length > 0 &&
                  pagedata.data[0].attributes.FreeData5.data.map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
              </select>
            </label>

            <label>
              <h4>
                {pagedata.data.length > 0 && pagedata.data[0].attributes.FreeData1.data[1]}
                <span>{pagedata.data.length > 0 ? pagedata.data[0].attributes.FreeData1.data[15] : '(optional)'}</span>
              </h4>
              <input type="text" id="register-form-last-name" />
            </label>
            <label>
              <h4>{pagedata.data.length > 0 && pagedata.data[0].attributes.FreeData1.data[5]}</h4>
              <input type="text" id="register-form-email" />
            </label>
            <label>
              <h4>{pagedata.data.length > 0 && pagedata.data[0].attributes.FreeData1.data[9]}</h4>
              <input type="text" id="register-form-phone" />
            </label>

            <label>
              <h4>{pagedata.data.length > 0 && pagedata.data[0].attributes.FreeData1.data[2]}</h4>
              <input type="text" id="register-form-address" />
            </label>
            <label>
              <h4>{pagedata.data.length > 0 && pagedata.data[0].attributes.FreeData1.data[3]}</h4>
              <input type="text" id="register-form-zipcode" />
            </label>
            <label>
              <h4>{pagedata.data.length > 0 && pagedata.data[0].attributes.FreeData1.data[14]}</h4>
              <input type="text" id="register-form-city" />
            </label>
            <label>
              <h4>{pagedata.data.length > 0 && pagedata.data[0].attributes.FreeData1.data[4]}</h4>
              <input type="text" id="register-form-country" />
            </label>
            <label>
              <h4>
                {pagedata.data.length > 0 && pagedata.data[0].attributes.FreeData1.data[16]}
                <span>{pagedata.data.length > 0 && pagedata.data[0].attributes.FreeData1.data[15]}</span>
              </h4>
              <input type="text" id="register-form-config-number" placeholder="UFTSHDAF" />
            </label>
            <label>
              <h4>{pagedata.data.length > 0 && pagedata.data[0].attributes.FreeData1.data[13]}</h4>{' '}
              <textarea id="register-form-comment" type="text" />
            </label>
          </div>
        </div>

        <div className="InstallerRequest-inner-3">
          <div className="InstallerRequest-inner-3-button">
            <div>
              <button onClick={() => saveConfig()} id="ApplyButton">
                {pagedata.data.length > 0 && pagedata.data[0].attributes.PageTranslations.data[0]}
              </button>
              <p id="InstallerRequestError"></p>
            </div>
          </div>
        </div>
      </form>
      <NeedHelp color="grey"></NeedHelp>
      <NewsletterNew></NewsletterNew>
      <Footernew></Footernew>
    </>
  );
}

export default InstallerRequest;
