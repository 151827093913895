import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Utility } from '../utility';
import { Locale } from '../../model/enums/locale';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: Object.keys(Locale).map(Utility.formatLocale),
    fallbackLng: 'en',
    load: 'all',
  });

export default i18n;
