import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import HeaderSlim from './HeaderSlim';
import Footernew from './Footernew';
import { Helmet } from 'react-helmet';
import './Contact.css';
import NeedHelp from './NeedHelp';
function Contact() {
  let param = useParams();
  let [systemres, setsystemres] = useState({ data: [] });
  let [filterstate, setfilterstate] = useState('all');
  const location = useLocation();
  let couter = 0;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let la;
  let arrla;
  let arrla1 = '';

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' + arrla1 + '&filters[Slug][$eq]=Contact-page',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setsystemres(json);
      })
      .then(() => loadData());
  }, []);

  function loadData() {
    if (systemres.data.length > 0) {
      return (
        <>
          <h3>{systemres.data[0].attributes.FreeData1.data[0]}</h3>
          {systemres.data[0].attributes.FreeData1.data[1].map((item, index) => {
            return (
              <div
                className="ContactpageLineHq headlineContacts"
                id={index == systemres.data[0].attributes.FreeData1.data[1].length - 1 && 'lastContactHQ2'}
              >
                <div>{item[0]}</div>
                <div>
                  {item[1].map((itemy) => {
                    if (itemy.match(/@/)) {
                      if (itemy.match(/@/)) {
                        return (
                          <>
                            <p className="EmailLineContact">
                              <a href={'mailto:' + itemy.replace('E. ', '')}>{itemy}</a>
                            </p>
                          </>
                        );
                      }
                      return (
                        <>
                          <p className="EmailLineContact">{itemy}</p>
                        </>
                      );
                    }
                    return (
                      <>
                        <p>{itemy}</p>
                      </>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      );
    }
  }

  function loadOtherData() {
    if (systemres.data.length > 0) {
      return (
        <>
          <div className="Contact-NA">
            <h2>North America</h2>
            {systemres.data[0].attributes.FreeData2.data.map((itemz, indexz) => {
              couter = couter + 1;
              return (
                <>
                  <div className="ContactsQrailingVisible">
                    <h3
                      onClick={() => {
                        if (document.querySelectorAll('.ContactsQrailing')[indexz].classList.contains('visibleQR')) {
                          document.querySelectorAll('.ContactsQrailing')[indexz].classList.remove('visibleQR');
                        } else {
                          document.querySelectorAll('.ContactsQrailing')[indexz].classList.add('visibleQR');
                        }
                        if (document.getElementById('QrailingC' + indexz).innerText == '+') {
                          document.getElementById('QrailingC' + indexz).innerText = '-';
                        } else {
                          document.getElementById('QrailingC' + indexz).innerText = '+';
                        }
                      }}
                    >
                      {itemz[0]}
                    </h3>
                    <button
                      id={'QrailingC' + indexz}
                      onClick={() => {
                        if (document.querySelectorAll('.ContactsQrailing')[indexz].classList.contains('visibleQR')) {
                          document.querySelectorAll('.ContactsQrailing')[indexz].classList.remove('visibleQR');
                        } else {
                          document.querySelectorAll('.ContactsQrailing')[indexz].classList.add('visibleQR');
                        }
                        if (document.getElementById('QrailingC' + indexz).innerText == '+') {
                          document.getElementById('QrailingC' + indexz).innerText = '-';
                        } else {
                          document.getElementById('QrailingC' + indexz).innerText = '+';
                        }
                      }}
                    >
                      +
                    </button>
                  </div>
                  <div className={'ContactsQrailing Contact' + indexz}>
                    <h3>{itemz[0]}</h3>
                    {itemz[1].map((item, index) => {
                      return (
                        <div className="ContactpageLineHq" id={index == itemz[1].length - 1 && 'lastContactHQ'}>
                          <div>{item[0]}</div>
                          <div>
                            {item[1].map((itemy) => {
                              if (itemy.match(/@/)) {
                                if (itemy.match(/@/)) {
                                  return (
                                    <>
                                      <p className="EmailLineContact">
                                        <a href={'mailto:' + itemy.replace('E. ', '')}>{itemy}</a>
                                      </p>
                                    </>
                                  );
                                }
                                return (
                                  <>
                                    <p className="EmailLineContact">{itemy}</p>
                                  </>
                                );
                              }
                              return (
                                <>
                                  <p>{itemy}</p>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}{' '}
          </div>

          <div className="Contact-SA">
            <h2>South America</h2>
            {systemres.data[0].attributes.FreeData3.data.map((itemz, indexz) => {
              couter = couter + 1;
              return (
                <>
                  <div className="ContactsQrailingVisible">
                    <h3
                      onClick={() => {
                        if (document.querySelectorAll('.ContactsQrailing5')[indexz].classList.contains('visibleQR')) {
                          document.querySelectorAll('.ContactsQrailing5')[indexz].classList.remove('visibleQR');
                        } else {
                          document.querySelectorAll('.ContactsQrailing5')[indexz].classList.add('visibleQR');
                        }
                        if (document.getElementById('Qrailing5' + indexz).innerText == '+') {
                          document.getElementById('Qrailing5' + indexz).innerText = '-';
                        } else {
                          document.getElementById('Qrailing5' + indexz).innerText = '+';
                        }
                      }}
                    >
                      {itemz[0]}
                    </h3>
                    <button
                      id={'Qrailing5' + indexz}
                      onClick={() => {
                        if (document.querySelectorAll('.ContactsQrailing5')[indexz].classList.contains('visibleQR')) {
                          document.querySelectorAll('.ContactsQrailing5')[indexz].classList.remove('visibleQR');
                        } else {
                          document.querySelectorAll('.ContactsQrailing5')[indexz].classList.add('visibleQR');
                        }
                        if (document.getElementById('Qrailing5' + indexz).innerText == '+') {
                          document.getElementById('Qrailing5' + indexz).innerText = '-';
                        } else {
                          document.getElementById('Qrailing5' + indexz).innerText = '+';
                        }
                      }}
                    >
                      +
                    </button>
                  </div>
                  <div className={'ContactsQrailing5 Contact' + indexz}>
                    <h3>{itemz[0]}</h3>
                    {itemz[1].map((item, index) => {
                      return (
                        <div className="ContactpageLineHq" id={index == itemz[1].length - 1 && 'lastContactHQ'}>
                          <div>{item[0]}</div>
                          <div>
                            {item[1].map((itemy) => {
                              if (itemy.match(/@/)) {
                                if (itemy.match(/@/)) {
                                  return (
                                    <>
                                      <p className="EmailLineContact">
                                        <a href={'mailto:' + itemy.replace('E. ', '')}>{itemy}</a>
                                      </p>
                                    </>
                                  );
                                }
                                return (
                                  <>
                                    <p className="EmailLineContact">{itemy}</p>
                                  </>
                                );
                              }
                              return (
                                <>
                                  <p>{itemy}</p>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}{' '}
          </div>
          <div className="Contact-Europe">
            <h2>Europe</h2>
            {systemres.data[0].attributes.FreeData4.data.map((itemz, indexz) => {
              return (
                <>
                  <div className="ContactsQrailingVisible">
                    <h3
                      onClick={() => {
                        if (
                          document
                            .querySelectorAll('.ContactsQrailing')
                            [couter + indexz - 1].classList.contains('visibleQR')
                        ) {
                          document
                            .querySelectorAll('.ContactsQrailing')
                            [couter + indexz - 1].classList.remove('visibleQR');
                        } else {
                          document
                            .querySelectorAll('.ContactsQrailing')
                            [couter + indexz - 1].classList.add('visibleQR');
                        }
                        if (document.getElementById('Qrailing' + indexz).innerText == '+') {
                          document.getElementById('Qrailing' + indexz).innerText = '-';
                        } else {
                          document.getElementById('Qrailing' + indexz).innerText = '+';
                        }
                      }}
                    >
                      {itemz[0]}
                    </h3>
                    <button
                      id={'Qrailing' + indexz}
                      onClick={() => {
                        if (
                          document
                            .querySelectorAll('.ContactsQrailing')
                            [couter + indexz - 1].classList.contains('visibleQR')
                        ) {
                          document
                            .querySelectorAll('.ContactsQrailing')
                            [couter + indexz - 1].classList.remove('visibleQR');
                        } else {
                          document
                            .querySelectorAll('.ContactsQrailing')
                            [couter + indexz - 1].classList.add('visibleQR');
                        }
                        if (document.getElementById('Qrailing' + indexz).innerText == '+') {
                          document.getElementById('Qrailing' + indexz).innerText = '-';
                        } else {
                          document.getElementById('Qrailing' + indexz).innerText = '+';
                        }
                      }}
                    >
                      +
                    </button>
                  </div>
                  <div className={'ContactsQrailing Contact' + indexz}>
                    <h3>{itemz[0]}</h3>
                    {itemz[1].map((item, index) => {
                      return (
                        <div className="ContactpageLineHq" id={index == itemz[1].length - 1 && 'lastContactHQ'}>
                          <div>{item[0]}</div>
                          <div>
                            {item[1].map((itemy) => {
                              if (itemy.match(/@/)) {
                                if (itemy.match(/@/)) {
                                  return (
                                    <>
                                      <p className="EmailLineContact">
                                        <a href={'mailto:' + itemy.replace('E. ', '')}>{itemy}</a>
                                      </p>
                                    </>
                                  );
                                }
                                return (
                                  <>
                                    <p className="EmailLineContact">{itemy}</p>
                                  </>
                                );
                              }
                              return (
                                <>
                                  <p>{itemy}</p>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
          <div className="Contact-Africa">
            <h2>Africa</h2>
            {systemres.data[0].attributes.FreeData5.data.map((itemz, indexz) => {
              return (
                <>
                  <div className="ContactsQrailingVisible">
                    <h3
                      onClick={() => {
                        if (document.querySelectorAll('.ContactsQrailing3')[indexz].classList.contains('visibleQR')) {
                          document.querySelectorAll('.ContactsQrailing3')[indexz].classList.remove('visibleQR');
                        } else {
                          document.querySelectorAll('.ContactsQrailing3')[indexz].classList.add('visibleQR');
                        }
                        if (document.getElementById('Qrailing3' + indexz).innerText == '+') {
                          document.getElementById('Qrailing3' + indexz).innerText = '-';
                        } else {
                          document.getElementById('Qrailing3' + indexz).innerText = '+';
                        }
                      }}
                    >
                      {itemz[0]}
                    </h3>
                    <button
                      id={'Qrailing3' + indexz}
                      onClick={() => {
                        if (document.querySelectorAll('.ContactsQrailing3')[indexz].classList.contains('visibleQR')) {
                          document.querySelectorAll('.ContactsQrailing3')[indexz].classList.remove('visibleQR');
                        } else {
                          document.querySelectorAll('.ContactsQrailing3')[indexz].classList.add('visibleQR');
                        }
                        if (document.getElementById('Qrailing3' + indexz).innerText == '+') {
                          document.getElementById('Qrailing3' + indexz).innerText = '-';
                        } else {
                          document.getElementById('Qrailing3' + indexz).innerText = '+';
                        }
                      }}
                    >
                      +
                    </button>
                  </div>
                  <div className={'ContactsQrailing3 Contact' + indexz}>
                    <h3>{itemz[0]}</h3>
                    {itemz[1].map((item, index) => {
                      return (
                        <div className="ContactpageLineHq" id={index == itemz[1].length - 1 && 'lastContactHQ'}>
                          <div>{item[0]}</div>
                          <div>
                            {item[1].map((itemy) => {
                              if (itemy.match(/@/)) {
                                if (itemy.match(/@/)) {
                                  return (
                                    <>
                                      <p className="EmailLineContact">
                                        <a href={'mailto:' + itemy.replace('E. ', '')}>{itemy}</a>
                                      </p>
                                    </>
                                  );
                                }
                                return (
                                  <>
                                    <p className="EmailLineContact">{itemy}</p>
                                  </>
                                );
                              }
                              return (
                                <>
                                  <p>{itemy}</p>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}{' '}
          </div>
          <div className="Contact-Asia">
            <h2>Asia</h2>
            {systemres.data[0].attributes.FreeData6.data.map((itemz, indexz) => {
              return (
                <>
                  <div className="ContactsQrailingVisible">
                    <h3
                      onClick={() => {
                        if (document.querySelectorAll('.ContactsQrailing4')[indexz].classList.contains('visibleQR')) {
                          document.querySelectorAll('.ContactsQrailing4')[indexz].classList.remove('visibleQR');
                        } else {
                          document.querySelectorAll('.ContactsQrailing4')[indexz].classList.add('visibleQR');
                        }
                        if (document.getElementById('Qrailing4' + indexz).innerText == '+') {
                          document.getElementById('Qrailing4' + indexz).innerText = '-';
                        } else {
                          document.getElementById('Qrailing4' + indexz).innerText = '+';
                        }
                      }}
                    >
                      {itemz[0]}
                    </h3>
                    <button
                      id={'Qrailing4' + indexz}
                      onClick={() => {
                        if (document.querySelectorAll('.ContactsQrailing4')[indexz].classList.contains('visibleQR')) {
                          document.querySelectorAll('.ContactsQrailing4')[indexz].classList.remove('visibleQR');
                        } else {
                          document.querySelectorAll('.ContactsQrailing4')[indexz].classList.add('visibleQR');
                        }
                        if (document.getElementById('Qrailing4' + indexz).innerText == '+') {
                          document.getElementById('Qrailing4' + indexz).innerText = '-';
                        } else {
                          document.getElementById('Qrailing4' + indexz).innerText = '+';
                        }
                      }}
                    >
                      +
                    </button>
                  </div>
                  <div className={'ContactsQrailing4 Contact' + indexz}>
                    <h3>{itemz[0]}</h3>
                    {itemz[1].map((item, index) => {
                      return (
                        <div className="ContactpageLineHq" id={index == itemz[1].length - 1 && 'lastContactHQ'}>
                          <div>{item[0]}</div>
                          <div>
                            {item[1].map((itemy) => {
                              if (itemy.match(/@/)) {
                                if (itemy.match(/@/)) {
                                  return (
                                    <>
                                      <p className="EmailLineContact">
                                        <a href={'mailto:' + itemy.replace('E. ', '')}>{itemy}</a>
                                      </p>
                                    </>
                                  );
                                }
                                return (
                                  <>
                                    <p className="EmailLineContact">{itemy}</p>
                                  </>
                                );
                              }
                              return (
                                <>
                                  <p>{itemy}</p>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}{' '}
          </div>
        </>
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>{systemres.data.length > 0 ? systemres.data[0].attributes.MetaTitle : 'Contact'}</title>
        <meta
          name="description"
          content={
            systemres.data.length > 0 ? systemres.data[0].attributes.MetaText : 'Q-railing offices and branches here'
          }
        ></meta>
      </Helmet>
      <HeaderSlim></HeaderSlim>
      <NeedHelp color="grey"></NeedHelp>
      <div className="Contact-wrap">
        <div className="Contact-inner">
          <div className="Contact-Header">
            <h2 className="Contact-Headline">
              {systemres.data.length > 0 ? systemres.data[0].attributes.FreeTitle1 : 'Offices'}
            </h2>
            <div className="Contact-Buttons">
              <button
                onClick={() => {
                  setfilterstate('all');
                  if (document.querySelector('.Contact-NA').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-NA').classList.remove('hidecontactblock');
                  }
                  if (document.querySelector('.Contact-SA').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-SA').classList.remove('hidecontactblock');
                  }
                  if (document.querySelector('.Contact-Europe').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Europe').classList.remove('hidecontactblock');
                  }
                  if (document.querySelector('.Contact-Africa').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Africa').classList.remove('hidecontactblock');
                  }
                  if (document.querySelector('.Contact-Asia').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Asia').classList.remove('hidecontactblock');
                  }
                  document.querySelector('.Contact-NA').scrollIntoView({ behavior: 'smooth', block: 'center' });
                }}
                className={filterstate == 'all' && 'activetogglebtn'}
              >
                All
              </button>
              <button
                onClick={() => {
                  setfilterstate('NA');
                  if (document.querySelector('.Contact-NA').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-NA').classList.remove('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-SA').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-SA').classList.add('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-Europe').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Europe').classList.add('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-Africa').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Africa').classList.add('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-Asia').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Asia').classList.add('hidecontactblock');
                  }
                  document.querySelector('.Contact-NA').scrollIntoView({ behavior: 'smooth', block: 'center' });
                }}
                className={filterstate == 'NA' && 'activetogglebtn'}
              >
                North America
              </button>
              <button
                onClick={() => {
                  setfilterstate('SA');
                  if (!document.querySelector('.Contact-NA').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-NA').classList.add('hidecontactblock');
                  }
                  if (document.querySelector('.Contact-SA').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-SA').classList.remove('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-Europe').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Europe').classList.add('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-Africa').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Africa').classList.add('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-Asia').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Asia').classList.add('hidecontactblock');
                  }
                  document.querySelector('.Contact-SA').scrollIntoView({ behavior: 'smooth', block: 'center' });
                }}
                className={filterstate == 'SA' && 'activetogglebtn'}
              >
                South America
              </button>
              <button
                onClick={() => {
                  setfilterstate('EU');
                  if (!document.querySelector('.Contact-NA').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-NA').classList.add('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-SA').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-SA').classList.add('hidecontactblock');
                  }
                  if (document.querySelector('.Contact-Europe').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Europe').classList.remove('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-Africa').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Africa').classList.add('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-Asia').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Asia').classList.add('hidecontactblock');
                  }
                  document.querySelector('.Contact-Europe').scrollIntoView({ behavior: 'smooth', block: 'center' });
                }}
                className={filterstate == 'EU' && 'activetogglebtn'}
              >
                Europe
              </button>
              <button
                onClick={() => {
                  setfilterstate('AF');
                  if (!document.querySelector('.Contact-NA').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-NA').classList.add('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-SA').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-SA').classList.add('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-Europe').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Europe').classList.add('hidecontactblock');
                  }
                  if (document.querySelector('.Contact-Africa').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Africa').classList.remove('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-Asia').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Asia').classList.add('hidecontactblock');
                  }
                  document.querySelector('.Contact-Africa').scrollIntoView({ behavior: 'smooth', block: 'center' });
                }}
                className={filterstate == 'AF' && 'activetogglebtn'}
              >
                Africa
              </button>
              <button
                onClick={() => {
                  setfilterstate('AS');
                  if (!document.querySelector('.Contact-NA').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-NA').classList.add('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-SA').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-SA').classList.add('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-Europe').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Europe').classList.add('hidecontactblock');
                  }
                  if (!document.querySelector('.Contact-Africa').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Africa').classList.add('hidecontactblock');
                  }
                  if (document.querySelector('.Contact-Asia').classList.contains('hidecontactblock')) {
                    document.querySelector('.Contact-Asia').classList.remove('hidecontactblock');
                  }
                  document.querySelector('.Contact-Asia').scrollIntoView({ behavior: 'smooth', block: 'center' });
                }}
                className={filterstate == 'AS' && 'activetogglebtn'}
              >
                Asia
              </button>
            </div>
          </div>
          {loadData()}
          {loadOtherData()}
        </div>
      </div>

      <Footernew></Footernew>
    </>
  );
}

export default Contact;
