import { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import './Service.css';

import HeaderSlim from './includes/HeaderSlim';

import LinkData from './includes/Links.json';
import { Helmet } from 'react-helmet';
import Faq from './includes/Faq';
import Footernew from './includes/Footernew';
import ServiceGeneralSearch from './includes/ServiceGeneralSearch';
import DownloadInSystems from './includes/DownloadInSystems';
import NeedHelp from './includes/NeedHelp';
function Service() {
  let [systemarr, setsystemarr] = useState({ data: [] });
  let la;
  let arrla;
  let param = useParams();
  let para = useParams();

  let arrla1 = '';
  let [linkarr, setlinkarr] = useState([]);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    decidethepaths();
  }, []);
  function decidethepaths() {
    if (LinkData) {
      let val = ['en', 'en'];

      if (para.count) {
        val = [para.count, 'en'];
        if (para.count.match('-')) {
          val = para.count.split('-');
        }
      }
      if (para.count == 'es') {
        val = ['es', 'es'];
      }
      switch (val[0]) {
        case 'de':
          setlinkarr(LinkData.data.de_arr);
          break;
        case 'da':
          setlinkarr(LinkData.data.da_arr);
          break;
        case 'en':
          setlinkarr(LinkData.data.globallangarr);
          break;
        case 'it':
          setlinkarr(LinkData.data.it_arr);
          break;
        case 'es':
          setlinkarr(LinkData.data.es_arr);
          break;
        case 'fi':
          setlinkarr(LinkData.data.fi_arr);
          break;
        case 'fr':
          setlinkarr(LinkData.data.fr_arr);
          break;
        case 'nb':
          setlinkarr(LinkData.data.nb_arr);
          break;
        case 'nl':
          setlinkarr(LinkData.data.nl_arr);
          break;
        case 'pl':
          setlinkarr(LinkData.data.pl_arr);
          break;
        case 'pt':
          setlinkarr(LinkData.data.pt_arr);
          break;
        case 'sv':
          setlinkarr(LinkData.data.sv_arr);
          break;
        case 'tr':
          setlinkarr(LinkData.data.tr_arr);
          break;

        default:
          setlinkarr(LinkData.data.en_arr);
          break;
      }
      if (val[0] == 'en' && val[1] == 'us') {
        setlinkarr(LinkData.data.en_us_arr);
      }
      if (val[0] == 'en' && val[1] == 'ca') {
        setlinkarr(LinkData.data.en_us_arr);
      }
    }
  }

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' + arrla1 + '&filters[Slug][$eq]=Service-page',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setsystemarr(json);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{systemarr.data.length > 0 ? systemarr.data[0].attributes.MetaTitle : 'Service'}</title>
        <meta
          name="description"
          content={
            systemarr.data.length > 0 ? systemarr.data[0].attributes.MetaText : 'the premium brand in railing systems'
          }
        ></meta>
      </Helmet>

      <HeaderSlim></HeaderSlim>

      {linkarr.length > 0 && (
        <>
          {' '}
          <div className="Service-wrap">
            <div className="Service-inner">
              <h2>{systemarr.data.length > 0 ? systemarr.data[0].attributes.FreeTitle1 : 'Service'}</h2>
            </div>
          </div>
          <DownloadInSystems></DownloadInSystems>
          <div className="Service-menu-wrap">
            <div className="Service-menu-inner">
              <div>
                <h2>
                  {systemarr.data.length > 0
                    ? systemarr.data[0].attributes.PageTranslations.data[0]
                    : 'CUSTOMER SUPPORT'}
                </h2>
                <Link className="Service-menu-support-link" to={'/' + para.count + linkarr[18]} hrefLang={para.count}>
                  {systemarr.data.length > 0 ? systemarr.data[0].attributes.PageTranslations.data[1] : 'Contact'}
                </Link>
                <Link
                  className="Service-menu-support-link"
                  to={'/' + para.count + '/service/catalogues-and-brochures/'}
                  hrefLang={para.count}
                >
                  {systemarr.data.length > 0 ? systemarr.data[0].attributes.PageTranslations.data[2] : 'Catalog'}
                </Link>
                <Link className="Service-menu-support-link" to={'/' + para.count + linkarr[14]} hrefLang={para.count}>
                  {systemarr.data.length > 0 ? systemarr.data[0].attributes.PageTranslations.data[3] : 'Trainigs'}
                </Link>
                <Link
                  className="Service-menu-support-link"
                  to={'/' + para.count + '/service/prefix/'}
                  hrefLang={para.count}
                >
                  {systemarr.data.length > 0 ? systemarr.data[0].attributes.PageTranslations.data[4] : 'Workshop'}
                </Link>
                {(para.count == 'de-de' || para.count == 'en-gb' || para.count == 'nl-nl') && (
                  <Link
                    className="Service-menu-support-link"
                    to={'/' + para.count + '/service/roadshow/'}
                    hrefLang={para.count}
                  >
                    {'Roadshow'}
                  </Link>
                )}
              </div>
              <div>
                <h2>
                  {systemarr.data.length > 0
                    ? systemarr.data[0].attributes.PageTranslations.data[5]
                    : 'PRODUCT SUPPORT'}
                </h2>

                <Link
                  id="ServicePage-firstLink"
                  hrefLang={para.count}
                  className="Service-menu-inner-link"
                  to={'/' + para.count + linkarr[13] + 'tendertext/'}
                >
                  {systemarr.data.length > 0
                    ? systemarr.data[0].attributes.PageTranslations.data[6]
                    : 'Installation manuals'}
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.75193 1V7.5C9.75193 7.69891 9.67291 7.88968 9.53226 8.03033C9.3916 8.17098 9.20084 8.25 9.00193 8.25C8.80301 8.25 8.61225 8.17098 8.4716 8.03033C8.33094 7.88968 8.25193 7.69891 8.25193 7.5V2.8125L1.53255 9.53063C1.39165 9.67152 1.20056 9.75068 1.0013 9.75068C0.802044 9.75068 0.610947 9.67152 0.470051 9.53063C0.329155 9.38973 0.25 9.19863 0.25 8.99938C0.25 8.80012 0.329155 8.60902 0.470051 8.46813L7.18943 1.75H2.50193C2.30301 1.75 2.11225 1.67098 1.9716 1.53033C1.83094 1.38968 1.75193 1.19891 1.75193 1C1.75193 0.801088 1.83094 0.610322 1.9716 0.46967C2.11225 0.329018 2.30301 0.25 2.50193 0.25H9.00193C9.20084 0.25 9.3916 0.329018 9.53226 0.46967C9.67291 0.610322 9.75193 0.801088 9.75193 1Z"
                      fill="black"
                    />
                  </svg>
                </Link>
                <Link
                  hrefLang={para.count}
                  className="Service-menu-inner-link"
                  to={'/' + para.count + linkarr[13] + 'bim/'}
                >
                  {systemarr.data.length > 0
                    ? systemarr.data[0].attributes.PageTranslations.data[7]
                    : 'Technical drawings'}
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.75193 1V7.5C9.75193 7.69891 9.67291 7.88968 9.53226 8.03033C9.3916 8.17098 9.20084 8.25 9.00193 8.25C8.80301 8.25 8.61225 8.17098 8.4716 8.03033C8.33094 7.88968 8.25193 7.69891 8.25193 7.5V2.8125L1.53255 9.53063C1.39165 9.67152 1.20056 9.75068 1.0013 9.75068C0.802044 9.75068 0.610947 9.67152 0.470051 9.53063C0.329155 9.38973 0.25 9.19863 0.25 8.99938C0.25 8.80012 0.329155 8.60902 0.470051 8.46813L7.18943 1.75H2.50193C2.30301 1.75 2.11225 1.67098 1.9716 1.53033C1.83094 1.38968 1.75193 1.19891 1.75193 1C1.75193 0.801088 1.83094 0.610322 1.9716 0.46967C2.11225 0.329018 2.30301 0.25 2.50193 0.25H9.00193C9.20084 0.25 9.3916 0.329018 9.53226 0.46967C9.67291 0.610322 9.75193 0.801088 9.75193 1Z"
                      fill="black"
                    />
                  </svg>
                </Link>
                <Link
                  hrefLang={para.count}
                  className="Service-menu-inner-link"
                  to={'/' + para.count + linkarr[13] + 'q-details/'}
                >
                  {systemarr.data.length > 0 ? systemarr.data[0].attributes.PageTranslations.data[15] : 'Q-details'}
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.75193 1V7.5C9.75193 7.69891 9.67291 7.88968 9.53226 8.03033C9.3916 8.17098 9.20084 8.25 9.00193 8.25C8.80301 8.25 8.61225 8.17098 8.4716 8.03033C8.33094 7.88968 8.25193 7.69891 8.25193 7.5V2.8125L1.53255 9.53063C1.39165 9.67152 1.20056 9.75068 1.0013 9.75068C0.802044 9.75068 0.610947 9.67152 0.470051 9.53063C0.329155 9.38973 0.25 9.19863 0.25 8.99938C0.25 8.80012 0.329155 8.60902 0.470051 8.46813L7.18943 1.75H2.50193C2.30301 1.75 2.11225 1.67098 1.9716 1.53033C1.83094 1.38968 1.75193 1.19891 1.75193 1C1.75193 0.801088 1.83094 0.610322 1.9716 0.46967C2.11225 0.329018 2.30301 0.25 2.50193 0.25H9.00193C9.20084 0.25 9.3916 0.329018 9.53226 0.46967C9.67291 0.610322 9.75193 0.801088 9.75193 1Z"
                      fill="black"
                    />
                  </svg>
                </Link>
                <Link
                  hrefLang={para.count}
                  className="Service-menu-inner-link"
                  to={'/' + para.count + linkarr[13] + 'cad/'}
                >
                  {systemarr.data.length > 0 ? systemarr.data[0].attributes.PageTranslations.data[8] : 'BIM files'}
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.75193 1V7.5C9.75193 7.69891 9.67291 7.88968 9.53226 8.03033C9.3916 8.17098 9.20084 8.25 9.00193 8.25C8.80301 8.25 8.61225 8.17098 8.4716 8.03033C8.33094 7.88968 8.25193 7.69891 8.25193 7.5V2.8125L1.53255 9.53063C1.39165 9.67152 1.20056 9.75068 1.0013 9.75068C0.802044 9.75068 0.610947 9.67152 0.470051 9.53063C0.329155 9.38973 0.25 9.19863 0.25 8.99938C0.25 8.80012 0.329155 8.60902 0.470051 8.46813L7.18943 1.75H2.50193C2.30301 1.75 2.11225 1.67098 1.9716 1.53033C1.83094 1.38968 1.75193 1.19891 1.75193 1C1.75193 0.801088 1.83094 0.610322 1.9716 0.46967C2.11225 0.329018 2.30301 0.25 2.50193 0.25H9.00193C9.20084 0.25 9.3916 0.329018 9.53226 0.46967C9.67291 0.610322 9.75193 0.801088 9.75193 1Z"
                      fill="black"
                    />
                  </svg>
                </Link>
                <Link
                  hrefLang={para.count}
                  className="Service-menu-inner-link"
                  to={'/' + para.count + linkarr[13] + 'manual/'}
                >
                  {systemarr.data.length > 0 ? systemarr.data[0].attributes.PageTranslations.data[9] : 'Dops'}
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.75193 1V7.5C9.75193 7.69891 9.67291 7.88968 9.53226 8.03033C9.3916 8.17098 9.20084 8.25 9.00193 8.25C8.80301 8.25 8.61225 8.17098 8.4716 8.03033C8.33094 7.88968 8.25193 7.69891 8.25193 7.5V2.8125L1.53255 9.53063C1.39165 9.67152 1.20056 9.75068 1.0013 9.75068C0.802044 9.75068 0.610947 9.67152 0.470051 9.53063C0.329155 9.38973 0.25 9.19863 0.25 8.99938C0.25 8.80012 0.329155 8.60902 0.470051 8.46813L7.18943 1.75H2.50193C2.30301 1.75 2.11225 1.67098 1.9716 1.53033C1.83094 1.38968 1.75193 1.19891 1.75193 1C1.75193 0.801088 1.83094 0.610322 1.9716 0.46967C2.11225 0.329018 2.30301 0.25 2.50193 0.25H9.00193C9.20084 0.25 9.3916 0.329018 9.53226 0.46967C9.67291 0.610322 9.75193 0.801088 9.75193 1Z"
                      fill="black"
                    />
                  </svg>
                </Link>

                <Link
                  hrefLang={para.count}
                  className="Service-menu-inner-link"
                  to={'/' + para.count + linkarr[13] + 'certificates/'}
                >
                  {systemarr.data.length > 0 ? systemarr.data[0].attributes.PageTranslations.data[11] : 'Datasheets'}
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.75193 1V7.5C9.75193 7.69891 9.67291 7.88968 9.53226 8.03033C9.3916 8.17098 9.20084 8.25 9.00193 8.25C8.80301 8.25 8.61225 8.17098 8.4716 8.03033C8.33094 7.88968 8.25193 7.69891 8.25193 7.5V2.8125L1.53255 9.53063C1.39165 9.67152 1.20056 9.75068 1.0013 9.75068C0.802044 9.75068 0.610947 9.67152 0.470051 9.53063C0.329155 9.38973 0.25 9.19863 0.25 8.99938C0.25 8.80012 0.329155 8.60902 0.470051 8.46813L7.18943 1.75H2.50193C2.30301 1.75 2.11225 1.67098 1.9716 1.53033C1.83094 1.38968 1.75193 1.19891 1.75193 1C1.75193 0.801088 1.83094 0.610322 1.9716 0.46967C2.11225 0.329018 2.30301 0.25 2.50193 0.25H9.00193C9.20084 0.25 9.3916 0.329018 9.53226 0.46967C9.67291 0.610322 9.75193 0.801088 9.75193 1Z"
                      fill="black"
                    />
                  </svg>
                </Link>
                <Link
                  hrefLang={para.count}
                  className="Service-menu-inner-link"
                  to={'/' + para.count + linkarr[13] + 'dops/'}
                >
                  {systemarr.data.length > 0 ? systemarr.data[0].attributes.PageTranslations.data[14] : 'Tendertext'}
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.75193 1V7.5C9.75193 7.69891 9.67291 7.88968 9.53226 8.03033C9.3916 8.17098 9.20084 8.25 9.00193 8.25C8.80301 8.25 8.61225 8.17098 8.4716 8.03033C8.33094 7.88968 8.25193 7.69891 8.25193 7.5V2.8125L1.53255 9.53063C1.39165 9.67152 1.20056 9.75068 1.0013 9.75068C0.802044 9.75068 0.610947 9.67152 0.470051 9.53063C0.329155 9.38973 0.25 9.19863 0.25 8.99938C0.25 8.80012 0.329155 8.60902 0.470051 8.46813L7.18943 1.75H2.50193C2.30301 1.75 2.11225 1.67098 1.9716 1.53033C1.83094 1.38968 1.75193 1.19891 1.75193 1C1.75193 0.801088 1.83094 0.610322 1.9716 0.46967C2.11225 0.329018 2.30301 0.25 2.50193 0.25H9.00193C9.20084 0.25 9.3916 0.329018 9.53226 0.46967C9.67291 0.610322 9.75193 0.801088 9.75193 1Z"
                      fill="black"
                    />
                  </svg>
                </Link>
                <Link
                  hrefLang={para.count}
                  className="Service-menu-inner-link"
                  to={'/' + para.count + linkarr[13] + 'datasheets/'}
                >
                  {systemarr.data.length > 0 ? systemarr.data[0].attributes.PageTranslations.data[12] : 'Tendertext'}
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.75193 1V7.5C9.75193 7.69891 9.67291 7.88968 9.53226 8.03033C9.3916 8.17098 9.20084 8.25 9.00193 8.25C8.80301 8.25 8.61225 8.17098 8.4716 8.03033C8.33094 7.88968 8.25193 7.69891 8.25193 7.5V2.8125L1.53255 9.53063C1.39165 9.67152 1.20056 9.75068 1.0013 9.75068C0.802044 9.75068 0.610947 9.67152 0.470051 9.53063C0.329155 9.38973 0.25 9.19863 0.25 8.99938C0.25 8.80012 0.329155 8.60902 0.470051 8.46813L7.18943 1.75H2.50193C2.30301 1.75 2.11225 1.67098 1.9716 1.53033C1.83094 1.38968 1.75193 1.19891 1.75193 1C1.75193 0.801088 1.83094 0.610322 1.9716 0.46967C2.11225 0.329018 2.30301 0.25 2.50193 0.25H9.00193C9.20084 0.25 9.3916 0.329018 9.53226 0.46967C9.67291 0.610322 9.75193 0.801088 9.75193 1Z"
                      fill="black"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
      {systemarr.data.length > 0 && <ServiceGeneralSearch></ServiceGeneralSearch>}
      {systemarr.data.length > 0 && <Faq service={false} color="grey" topic={'service'}></Faq>}
      {systemarr.data.length > 0 && <NeedHelp></NeedHelp>}
      {systemarr.data.length > 0 && <Footernew></Footernew>}
    </>
  );
}

export default Service;
