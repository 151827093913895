import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Faq.css';

function Faq(props) {
  let [dataa, setdataa] = useState({ data: [] });
  let [payment, setpayment] = useState({ data: [] });
  let [faq, setfaq] = useState({ data: [] });
  let [faqfilter, setfaqfilter] = useState('all');
  let [delivery, setdelivery] = useState({ data: [] });
  let [quality, setquality] = useState({ data: [] });
  let para = useParams();
  let la;
  let arrla;
  let arrla1;
  let thearr = [];
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  if (para.count) {
    la = para.count;
    if (la.match('-')) {
      arrla = la.split('-');
      if (arrla[1]) {
        arrla[1] = arrla[1].toUpperCase();
        arrla1 = arrla[0] + '-' + arrla[1];
      }
    } else {
      arrla1 = para.count;
    }
  } else {
    la = 'en';
  }

  useEffect(() => {
    if (document.querySelector('.faq-wrapper') && props.color) {
      if (props.color == 'white') {
        document.querySelector('.faq-wrapper').style = 'background-color: white !important;';
      }
    }

    if (props.service == true) {
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/Faqs?locale=' + arrla1 + '&filters[Slug][$eq]=quality',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setquality(json);
        });
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/Faqs?locale=' + arrla1 + '&filters[Slug][$eq]=payment',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setpayment(json);
        });
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/Faqs?locale=' + arrla1 + '&filters[Slug][$eq]=faq',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setfaq(json);
        });

      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/Faqs?locale=' + arrla1 + '&filters[Slug][$eq]=delivery',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setdelivery(json);
        })
        .then(() => {
          loadMassfaq();
        });
    }
  }, []);

  useEffect(() => {
    if (props.topic) {
      let varrename = props.topic;
      if (props.topic == 'frenchbalcony') {
        varrename = 'juliet-balcony';
      }
      if (props.topic == 'glassrailing') {
        varrename = 'glass-balustrades';
      }
      if (props.topic == 'stairs') {
        varrename = 'stair-railing';
      }
      if (props.topic == 'balcony') {
        varrename = 'balcony-railing';
      }
      if (props.topic == 'handrail') {
        varrename = 'handrails';
      }
      if (props.topic == 'railings') {
        varrename = 'balustrades';
      }
      if (props.topic == 'Installer') {
        varrename = 'installer';
      }
      if (props.topic == 'Architect') {
        varrename = 'architect';
      }

      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/Faqs?locale=' + arrla1 + '&filters[Slug][$eq]=' + varrename,
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setdataa(json);
        })
        .then(() => loadfaq());
    }
  }, [props.topic]);

  function loadFaqFilter() {}

  function toggelquestions(nbr) {
    let tochange = document.getElementById('faq-question' + nbr);
    let btnchange = document.getElementById('faq-toggle-button' + nbr);

    if (tochange.classList.contains('visibilityclass')) {
      tochange.classList.remove('visibilityclass');
      //btnchange.innerText = ("+")
      btnchange.innerHTML =
        '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.25 7C13.25 7.19891 13.171 7.38968 13.0303 7.53033C12.8897 7.67098 12.6989 7.75 12.5 7.75H7.75V12.5C7.75 12.6989 7.67098 12.8897 7.53033 13.0303C7.38968 13.171 7.19891 13.25 7 13.25C6.80109 13.25 6.61032 13.171 6.46967 13.0303C6.32902 12.8897 6.25 12.6989 6.25 12.5V7.75H1.5C1.30109 7.75 1.11032 7.67098 0.96967 7.53033C0.829018 7.38968 0.75 7.19891 0.75 7C0.75 6.80109 0.829018 6.61032 0.96967 6.46967C1.11032 6.32902 1.30109 6.25 1.5 6.25H6.25V1.5C6.25 1.30109 6.32902 1.11032 6.46967 0.96967C6.61032 0.829018 6.80109 0.75 7 0.75C7.19891 0.75 7.38968 0.829018 7.53033 0.96967C7.67098 1.11032 7.75 1.30109 7.75 1.5V6.25H12.5C12.6989 6.25 12.8897 6.32902 13.0303 6.46967C13.171 6.61032 13.25 6.80109 13.25 7Z" fill="#001020"/></svg>';
      btnchange.classList.remove('faq-active-btn');
    } else {
      //btnchange.innerText = ("x")
      btnchange.innerHTML =
        '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0306 9.96997C11.1715 10.1109 11.2506 10.302 11.2506 10.5012C11.2506 10.7005 11.1715 10.8916 11.0306 11.0325C10.8897 11.1734 10.6986 11.2525 10.4993 11.2525C10.3001 11.2525 10.109 11.1734 9.9681 11.0325L5.99997 7.0631L2.0306 11.0312C1.8897 11.1721 1.69861 11.2513 1.49935 11.2513C1.30009 11.2513 1.10899 11.1721 0.968098 11.0312C0.827202 10.8903 0.748047 10.6992 0.748047 10.5C0.748047 10.3007 0.827202 10.1096 0.968098 9.96872L4.93747 6.0006L0.969348 2.03122C0.828451 1.89033 0.749297 1.69923 0.749297 1.49997C0.749297 1.30072 0.828451 1.10962 0.969348 0.968723C1.11024 0.827827 1.30134 0.748672 1.5006 0.748672C1.69986 0.748672 1.89095 0.827827 2.03185 0.968723L5.99997 4.9381L9.96935 0.968098C10.1102 0.827201 10.3013 0.748047 10.5006 0.748047C10.6999 0.748047 10.891 0.827201 11.0318 0.968098C11.1727 1.10899 11.2519 1.30009 11.2519 1.49935C11.2519 1.69861 11.1727 1.8897 11.0318 2.0306L7.06247 6.0006L11.0306 9.96997Z" fill="white"/></svg>';
      tochange.classList.add('visibilityclass');
      btnchange.classList.add('faq-active-btn');
    }
  }

  function loadfaq() {
    if (dataa.data.length > 0) {
      return (
        <>
          {dataa.data.length > 0 &&
            dataa.data.map((item, index) => {
              return (
                <>
                  <div
                    onClick={() => {
                      toggelquestions(index);
                    }}
                    className={'faq-question-warp FAQ-ID-' + item.attributes.TrackingID}
                    id={props.color == 'grey' ? 'faq-grey-quest' : 'faq-white-quest'}
                  >
                    <div className="faq-question-question">
                      <p>{item.attributes.Question}</p>
                      <button
                        aria-label={'OpenQuestion' + index}
                        className={'faq-toggle-buttons FAQ-ID-' + item.attributes.TrackingID}
                        id={'faq-toggle-button' + index}
                      >
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M13.25 7C13.25 7.19891 13.171 7.38968 13.0303 7.53033C12.8897 7.67098 12.6989 7.75 12.5 7.75H7.75V12.5C7.75 12.6989 7.67098 12.8897 7.53033 13.0303C7.38968 13.171 7.19891 13.25 7 13.25C6.80109 13.25 6.61032 13.171 6.46967 13.0303C6.32902 12.8897 6.25 12.6989 6.25 12.5V7.75H1.5C1.30109 7.75 1.11032 7.67098 0.96967 7.53033C0.829018 7.38968 0.75 7.19891 0.75 7C0.75 6.80109 0.829018 6.61032 0.96967 6.46967C1.11032 6.32902 1.30109 6.25 1.5 6.25H6.25V1.5C6.25 1.30109 6.32902 1.11032 6.46967 0.96967C6.61032 0.829018 6.80109 0.75 7 0.75C7.19891 0.75 7.38968 0.829018 7.53033 0.96967C7.67098 1.11032 7.75 1.30109 7.75 1.5V6.25H12.5C12.6989 6.25 12.8897 6.32902 13.0303 6.46967C13.171 6.61032 13.25 6.80109 13.25 7Z"
                            fill="#001020"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="faq-question-answers" id={'faq-question' + index}>
                      <div key={index + 'Link'} dangerouslySetInnerHTML={{ __html: item.attributes.Answer }} />
                    </div>
                  </div>
                </>
              );
            })}
        </>
      );
    }
  }

  function loadMassfaq(propss) {
    if (payment.data.length > 0) {
      if (quality.data.length > 0) {
        thearr.push(...quality.data);
      }
      if (payment.data.length > 0) {
        thearr.push(...payment.data);
      }
      if (delivery.data.length > 0) {
        thearr.push(...delivery.data);
      }
      if (faq.data.length > 0) {
        thearr.push(...faq.data);
      }

      if (propss == 'payment' || faqfilter == 'payment') {
        thearr = payment.data;
      }
      if (propss == 'delivery' || faqfilter == 'delivery') {
        thearr = delivery.data;
      }
      if (propss == 'quality' || faqfilter == 'quality') {
        thearr = quality.data;
      }
      if (propss == 'faq' || faqfilter == 'faq') {
        thearr = faq.data;
      }

      return (
        <>
          {thearr.length > 0 &&
            thearr.map((item, index) => {
              return (
                <>
                  <div className="faq-question-warp">
                    <div>
                      <p>{item.attributes.Question}</p>
                      <button
                        onClick={() => {
                          toggelquestions(index);
                        }}
                        aria-label={'OpenQuestion' + index}
                        className="faq-toggle-buttons"
                        id={'faq-toggle-button' + index}
                      >
                        +
                      </button>
                    </div>
                    <div className="faq-question-answers" id={'faq-question' + index}>
                      <div key={index + 'Link'} dangerouslySetInnerHTML={{ __html: item.attributes.Answer }} />
                    </div>
                  </div>
                </>
              );
            })}
        </>
      );
    }
  }

  return (
    <>
      {(dataa.data.length > 0 || delivery.data.length > 0 || payment.data.length > 0 || faq.data.length > 0) && (
        <div className="faq-wrapper" id={props.color == 'grey' ? 'faq-grey-wrap' : 'faq-white-wrap'}>
          <div className="faq-inner">
            <div>
              <h2>FAQ</h2>
            </div>

            {props.service && (
              <div className="ServiceFaqButtons">
                <button
                  className={faqfilter == 'all' && 'activebtnService'}
                  onClick={() => {
                    setfaqfilter('all');
                    loadFaqFilter('all');
                  }}
                >
                  All
                </button>
                <button
                  className={faqfilter == 'payment' && 'activebtnService'}
                  onClick={() => {
                    setfaqfilter('payment');
                    loadFaqFilter('payment');
                  }}
                >
                  Payment
                </button>
                <button
                  className={faqfilter == 'faq' && 'activebtnService'}
                  onClick={() => {
                    setfaqfilter('faq');
                    loadFaqFilter('faq');
                  }}
                >
                  FAQ
                </button>
                <button
                  className={faqfilter == 'delivery' && 'activebtnService'}
                  onClick={() => {
                    setfaqfilter('delivery');
                    loadFaqFilter('delivery');
                  }}
                >
                  Delivery
                </button>
                <button
                  className={faqfilter == 'quality' && 'activebtnService'}
                  onClick={() => {
                    setfaqfilter('quality');
                    loadFaqFilter('quality');
                  }}
                >
                  Quality
                </button>
              </div>
            )}
            {loadfaq()}
            {loadMassfaq()}
          </div>
        </div>
      )}
      {dataa.data.length == 0 && <div id="FAQ-shadowdiv"></div>}
    </>
  );
}

export default Faq;
