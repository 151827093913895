import daDK from './route-translations/routes.da-dk.json';
import deAT from './route-translations/routes.de-at.json';
import deCH from './route-translations/routes.de-ch.json';
import deDE from './route-translations/routes.de-de.json';
import en from './route-translations/routes.en.json';
import enAT from './route-translations/routes.en-at.json';
import enCA from './route-translations/routes.en-ca.json';
import enGB from './route-translations/routes.en-gb.json';
import enIE from './route-translations/routes.en-ie.json';
import enUS from './route-translations/routes.en-us.json';
import es from './route-translations/routes.es.json';
import esES from './route-translations/routes.es-es.json';
import esUS from './route-translations/routes.es-us.json';
import fiFI from './route-translations/routes.fi-fi.json';
import frBE from './route-translations/routes.fr-be.json';
import frCA from './route-translations/routes.fr-ca.json';
import frCH from './route-translations/routes.fr-ch.json';
import frFR from './route-translations/routes.fr-fr.json';
import frLU from './route-translations/routes.fr-lu.json';
import itCH from './route-translations/routes.it-ch.json';
import itIT from './route-translations/routes.it-it.json';
import nbNO from './route-translations/routes.nb-no.json';
import nlBE from './route-translations/routes.nl-be.json';
import nlNL from './route-translations/routes.nl-nl.json';
import plPL from './route-translations/routes.pl-pl.json';
import ptBR from './route-translations/routes.pt-br.json';
import ptPT from './route-translations/routes.pt-pt.json';
import svSE from './route-translations/routes.sv-se.json';
import trTR from './route-translations/routes.tr-tr.json';

const routeTranslations = {
  'da-dk': daDK,
  'de-at': deAT,
  'de-ch': deCH,
  'de-de': deDE,
  en: en,
  'en-at': enAT,
  'en-ca': enCA,
  'en-gb': enGB,
  'en-ie': enIE,
  'en-us': enUS,
  es: es,
  'es-es': esES,
  'es-us': esUS,
  'fi-fi': fiFI,
  'fr-be': frBE,
  'fr-ca': frCA,
  'fr-ch': frCH,
  'fr-fr': frFR,
  'fr-lu': frLU,
  'it-ch': itCH,
  'it-it': itIT,
  'nb-no': nbNO,
  'nl-be': nlBE,
  'nl-nl': nlNL,
  'pl-pl': plPL,
  'pt-br': ptBR,
  'pt-pt': ptPT,
  'sv-se': svSE,
  'tr-tr': trTR,
};

export default routeTranslations;
