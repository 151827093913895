import React, { useEffect, useState } from 'react';
import './SystemFinderEntry.css';
import { Link, useParams } from 'react-router-dom';
import { translateRoute } from 'utility/routing/common/routing';

function SystemFinderEntry() {
  let la;
  let arrla;
  let param = useParams();
  let arrla1 = 'en';
  const [PageTranslations, setPageTranslations] = useState({ data: [] });

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }
  let token = process.env.REACT_APP_PUBLIC_KEY2;

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=SystemFinderEntry',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setPageTranslations(json);
      });
  }, []);

  return (
    <div className="SystemFinderEntry-wrap">
      <div className="SystemFinderEntry-inner">
        <div className="SystemFinderEntry-Headline">
          <h2>{PageTranslations.data.length > 0 && PageTranslations.data[0].attributes.FreeTitle1}</h2>
          <p>{PageTranslations.data.length > 0 && PageTranslations.data[0].attributes.FreeText1}</p>
        </div>

        <div className="SystemFinderEntry-cards">
          <div className="SystemFinderEntry-Subheadline">
            <h3>{PageTranslations.data.length > 0 && PageTranslations.data[0].attributes.FreeTitle2}</h3>
          </div>

          <div className="SystemFinderEntry-cards-inner">
            <div className="SystemFinderEntry-Card">
              {' '}
              <Link to={translateRoute('balustrades/systems/?g', param.count)}>
                <img
                  width={201}
                  height={143}
                  src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/systemfinder-entry_glassrailing.webp"
                  alt="SystemFinderCardsEnty1"
                />
                <h3>{PageTranslations.data.length > 0 && PageTranslations.data[0].attributes.FreeData1.data[0]}</h3>
              </Link>
            </div>

            <div className="SystemFinderEntry-Card">
              {' '}
              <Link to={translateRoute('balustrades/systems/?p', param.count)}>
                <img
                  width={201}
                  height={143}
                  src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/systemfinder-entry_postrailing.webp"
                  alt="SystemFinderCardsEnty1"
                />
                <h3>{PageTranslations.data.length > 0 && PageTranslations.data[0].attributes.FreeData1.data[1]}</h3>
              </Link>
            </div>
            <div className="SystemFinderEntry-Card">
              {' '}
              <Link to={translateRoute('balustrades/systems/?f', param.count)}>
                <img
                  width={201}
                  height={143}
                  src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/systemfinder-entry_julietbalcony.webp"
                  alt="SystemFinderCardsEnty1"
                />
                <h3>{PageTranslations.data.length > 0 && PageTranslations.data[0].attributes.FreeData1.data[2]}</h3>
              </Link>
            </div>
            <div className="SystemFinderEntry-Card">
              {' '}
              <Link to={translateRoute('balustrades/systems/?h', param.count)}>
                <img
                  width={201}
                  height={143}
                  src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/systemfinder-entry_handrail.webp"
                  alt="SystemFinderCardsEnty1"
                />
                <h3>{PageTranslations.data.length > 0 && PageTranslations.data[0].attributes.FreeData1.data[3]}</h3>
              </Link>
            </div>
            <div className="SystemFinderEntry-Card">
              {' '}
              <Link to={translateRoute('balustrades/systems/', param.count)}>
                <h3>
                  <span>
                    {PageTranslations.data.length > 0 && PageTranslations.data[0].attributes.FreeTitle3}{' '}
                    <svg
                      style={{
                        rotate: '180deg',
                        textDecoration: 'underline',
                        marginBottom: '-2.5px',
                        marginLeft: '-4px',
                        borderTop: '1px solid #667079',
                      }}
                      width="17"
                      height="17"
                      viewBox="0 0 24 24"
                      fill="#667079"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.0006 11.9999C21.0006 12.1988 20.9216 12.3896 20.7809 12.5303C20.6403 12.6709 20.4495 12.7499 20.2506 12.7499H5.5609L11.0312 18.2193C11.1009 18.289 11.1562 18.3717 11.1939 18.4628C11.2316 18.5538 11.251 18.6514 11.251 18.7499C11.251 18.8485 11.2316 18.9461 11.1939 19.0371C11.1562 19.1281 11.1009 19.2109 11.0312 19.2806C10.9615 19.3502 10.8788 19.4055 10.7878 19.4432C10.6967 19.4809 10.5991 19.5003 10.5006 19.5003C10.402 19.5003 10.3045 19.4809 10.2134 19.4432C10.1224 19.4055 10.0396 19.3502 9.96996 19.2806L3.21996 12.5306C3.15023 12.4609 3.09491 12.3782 3.05717 12.2871C3.01943 12.1961 3 12.0985 3 11.9999C3 11.9014 3.01943 11.8038 3.05717 11.7127C3.09491 11.6217 3.15023 11.539 3.21996 11.4693L9.96996 4.7193C10.1107 4.57857 10.3016 4.49951 10.5006 4.49951C10.6996 4.49951 10.8905 4.57857 11.0312 4.7193C11.1719 4.86003 11.251 5.05091 11.251 5.24993C11.251 5.44895 11.1719 5.63982 11.0312 5.78055L5.5609 11.2499H20.2506C20.4495 11.2499 20.6403 11.3289 20.7809 11.4696C20.9216 11.6103 21.0006 11.801 21.0006 11.9999Z"
                        fill="#667079"
                      />
                    </svg>
                  </span>
                </h3>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemFinderEntry;
