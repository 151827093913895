import HeaderSlim from './includes/HeaderSlim';
import Footernew from './includes/Footernew';
import Contenttext from './includes/Contenttext';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import NeedHelp from './includes/NeedHelp';
import { Helmet } from 'react-helmet';
import { MetaLocaleRoutes } from './meta-tags/meta-locale-routes';

function Textpage(props) {
  const cookieDeclarationContainerRef = useRef(null);

  let param = useParams();
  let arrla;
  let arrla1 = '';
  let [passedprops, setpassedprops] = useState('');

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  let la;

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let [renderdata, setrenderdata] = useState();
  useEffect(() => {
    if (props.text) {
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/texts?locale=' + arrla1 + '&filters[Slug][$eq]=' + props.text,
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setrenderdata(json);
        });
    }
  }, []);
  useEffect(() => {
    if (props.text) {
      setpassedprops(props.text);
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/texts?locale=' + arrla1 + '&filters[Slug][$eq]=' + props.text,
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setrenderdata(json);
        });
    }
  }, [props.text]);

  useEffect(() => {
    if (props.text !== 'Text-content-Datenschutz' || !cookieDeclarationContainerRef.current) return;

    const script = document.createElement('script');
    script.src = 'https://consent.cookiebot.com/2849e929-3b80-4c99-abd6-8e18a011a647/cd.js';
    script.async = true;
    script.id = 'CookieDeclaration';
    script.type = 'text/javascript';

    cookieDeclarationContainerRef.current.appendChild(script);

    return () => {
      document.getElementById('CookieDeclaration')?.remove();
    };
  }, [props.text, cookieDeclarationContainerRef]);

  let slicedtext = props.text.split('-');
  console.log(slicedtext);
  let slicedtextinclude = slicedtext[0];
  if (props.text == 'Text-content-Impressum') {
    slicedtextinclude = 'legal';
  }
  if (props.text == 'Text-content-Agbs') {
    slicedtextinclude = 'terms';
  }
  if (props.text == 'Text-content-Datenschutz') {
    slicedtextinclude = 'privacy-policy';
  }

  return (
    <>
      <Helmet>
        <title>{renderdata ? renderdata.data[0].attributes.MetaTitle : 'Q-railing'}</title>
        <meta
          name="description"
          content={renderdata ? renderdata.data[0].attributes.MetaText : 'Q-railing Textpage'}
        ></meta>
      </Helmet>
      <HeaderSlim></HeaderSlim>
      <Contenttext text={renderdata} idclass={passedprops}></Contenttext>
      {props.text === 'Text-content-Datenschutz' && (
        <div className="Contenttext-wrap">
          <div className="Contenttext-inner">
            <div className="Contenttext-inner-inner">
              <hr style={{ width: '50%', marginLeft: '25%' }}></hr>
              <div
                ref={cookieDeclarationContainerRef}
                id="cookie-declaration"
                style={{ color: '#667079' }}
                className="Contenttext-inner-inner"
              ></div>
            </div>
          </div>
        </div>
      )}
      {renderdata && <NeedHelp color={'grey'}></NeedHelp>}
      {renderdata && <Footernew></Footernew>}
    </>
  );
}

export default Textpage;
