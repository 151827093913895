import React from 'react';
import HeaderSlim from './includes/HeaderSlim';
import Footernew from './includes/Footernew';
import NewsletterNew from './includes/NewsletterNew';
import SearchresultsMeili from './SearchresultsMeili';
import { Helmet } from 'react-helmet';

function SearchresultsPage() {
  return (
    <>
      <Helmet>
        <title>Search</title>
        <meta name="description" content="Q-railing Search"></meta>
      </Helmet>
      <HeaderSlim></HeaderSlim>
      <SearchresultsMeili searchpage={true}></SearchresultsMeili>
      <NewsletterNew color="grey"></NewsletterNew>
      <Footernew></Footernew>
    </>
  );
}

export default SearchresultsPage;
