import React, { useState, useEffect } from 'react';
import './NewsletterNew.css';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function NewsletterNew(props) {
  let [infotext, setinfotext] = useState({ data: [] });

  useEffect(() => {
    fetchdata(props.language);
  }, [props.language]);

  let param = useParams();
  let la;

  useEffect(() => {
    if (infotext.data.length > 0) {
      if (props.color) {
        if (props.color == 'grey') {
          document.querySelector('.NewsletterNew-wrap').style = 'background-color:#f2f3f4';
          if (document.querySelector('#fieldEmail')) {
            document.querySelector('#fieldEmail').style = 'background-color:white ';
          }
        }
      }
    }
  }, [infotext]);
  let arrla;
  let arrla1 = '';
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  async function fetchdata() {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=NewsletterInviteSlug',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => setinfotext(json))
      .then(() => rendernewsletter());
  }

  function rendernewsletter() {
    if (infotext.data.length > 0) {
      return (
        <>
          <div className="NewsletterNew-wrap">
            <div className="NewsletterNew-inner">
              <div className="NewsletterNew-left">
                <h3>{infotext.data[0].attributes.FreeTitle1}</h3>

                <p>{infotext.data[0].attributes.FreeText1}</p>

                <div
                  key={'SystemCustomText'}
                  dangerouslySetInnerHTML={{ __html: infotext.data[0].attributes.CustomText }}
                />

                <div id="Newsletter-disclaimer-wrap">
                  <div id="Newsletter-disclaimer">
                    <label htmlFor="NewsletterNew-checkbox"></label>
                    <p>{infotext.data[0].attributes.FreeText2}</p>
                  </div>
                </div>

                <Helmet>
                  <script
                    type="text/javascript"
                    src="https://strapiwmc4hstore.blob.core.windows.net/website-scripts/copypastesubscribeformlogic.js"
                  ></script>
                </Helmet>
              </div>
            </div>
          </div>
        </>
      );
    }
    return <></>;
  }

  return <> {rendernewsletter()}</>;
}

export default NewsletterNew;
