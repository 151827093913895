import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import HeaderSlim from './includes/HeaderSlim';
import './Jobcenter.css';
import './Trainings.css';
import { useParams, Link, useLocation } from 'react-router-dom';
import Footernew from './includes/Footernew';
import LinkData from './includes/Links.json';
import NeedHelp from './includes/NeedHelp';

function TrainingsOverview() {
  const params = useParams();
  const para = useParams();
  let param = useParams();
  let [systemres, setsystemres] = useState({ data: [] });
  let [posicount, setposicount] = useState(0);
  let [linkarr, setlinkarr] = useState([]);
  let [pagetranslations, setpagetranslations] = useState({ data: [] });
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    decidethepaths();
  }, []);

  function decidethepaths() {
    if (LinkData) {
      let val = ['en', 'en'];

      if (para.count) {
        val = [para.count, 'en'];
        if (para.count.match('-')) {
          val = para.count.split('-');
        }
      }
      if (para.count == 'es') {
        val = ['es', 'es'];
      }
      switch (val[0]) {
        case 'de':
          setlinkarr(LinkData.data.de_arr);
          break;
        case 'da':
          setlinkarr(LinkData.data.da_arr);
          break;
        case 'en':
          setlinkarr(LinkData.data.globallangarr);
          break;
        case 'it':
          setlinkarr(LinkData.data.it_arr);
          break;
        case 'es':
          setlinkarr(LinkData.data.es_arr);
          break;
        case 'fi':
          setlinkarr(LinkData.data.fi_arr);
          break;
        case 'fr':
          setlinkarr(LinkData.data.fr_arr);
          break;
        case 'nb':
          setlinkarr(LinkData.data.nb_arr);
          break;
        case 'nl':
          setlinkarr(LinkData.data.nl_arr);
          break;
        case 'pl':
          setlinkarr(LinkData.data.pl_arr);
          break;
        case 'pt':
          setlinkarr(LinkData.data.pt_arr);
          break;
        case 'sv':
          setlinkarr(LinkData.data.sv_arr);
          break;
        case 'tr':
          setlinkarr(LinkData.data.tr_arr);
          break;

        default:
          setlinkarr(LinkData.data.en_arr);
          break;
      }
      if (val[0] == 'en' && val[1] == 'us') {
        setlinkarr(LinkData.data.en_us_arr);
      }
      if (val[0] == 'en' && val[1] == 'ca') {
        setlinkarr(LinkData.data.en_us_arr);
      }
    }
  }

  let la;
  let arrla;
  let arrla1 = '';

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };
  useEffect(() => {
    fetch('https://strapi-qrailing.azurewebsites.net/api/trainings?locale=' + arrla1 + '&sort=Date', requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setsystemres(json);
        setposicount(json.data.length);
      });

    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=TrainingPageTranslations',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setpagetranslations(json);
      });
  }, []);

  function rendercards() {
    if (systemres.data.length > 0) {
      posicount = systemres.data.length;
      return (
        <>
          {systemres.data.map((item, index) => {
            return (
              <Link
                to={'/' + params.count + linkarr[14] + item.attributes.Slug}
                key={'Key' + index}
                className="jobcenter-jobcard TrainingsOverview-Card"
              >
                <div className="TrainingsOverview-Card-inner-left">
                  <h2>{item.attributes.Title}</h2>
                  <p>
                    {item.attributes.Date} • {item.attributes.Location}
                  </p>
                </div>
                <div className="TrainingsOverview-Linkside">
                  <Link
                    hrefLang={param.count}
                    className="TrainingsOverview-Link"
                    to={'/' + params.count + linkarr[14] + item.attributes.Slug}
                  >
                    {item.attributes.PageTranslations.data[2]}
                  </Link>
                  <Link
                    hrefLang={param.count}
                    className="TrainingsOverview-Link-responsive"
                    to={'/' + params.count + linkarr[14] + item.attributes.Slug}
                  >
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.0306 5.46878L8.53063 0.968777C8.38973 0.827881 8.19863 0.748726 7.99938 0.748726C7.80012 0.748726 7.60902 0.827881 7.46813 0.968777C7.32723 1.10967 7.24807 1.30077 7.24807 1.50003C7.24807 1.69928 7.32723 1.89038 7.46813 2.03128L10.6875 5.2494H1.5C1.30109 5.2494 1.11032 5.32842 0.96967 5.46907C0.829018 5.60972 0.75 5.80049 0.75 5.9994C0.75 6.19831 0.829018 6.38908 0.96967 6.52973C1.11032 6.67038 1.30109 6.7494 1.5 6.7494H10.6875L7.46937 9.9694C7.32848 10.1103 7.24932 10.3014 7.24932 10.5007C7.24932 10.6999 7.32848 10.891 7.46937 11.0319C7.61027 11.1728 7.80137 11.252 8.00062 11.252C8.19988 11.252 8.39098 11.1728 8.53187 11.0319L13.0319 6.5319C13.1018 6.46213 13.1573 6.37923 13.1951 6.28797C13.2329 6.1967 13.2523 6.09887 13.2522 6.00008C13.252 5.9013 13.2324 5.80351 13.1944 5.71233C13.1564 5.62115 13.1007 5.53838 13.0306 5.46878Z"
                        fill="#001020"
                      />
                    </svg>
                  </Link>
                </div>
              </Link>
            );
          })}
        </>
      );
    }
  }
  return (
    <>
      <Helmet>
        <title>{pagetranslations.data.length > 0 ? pagetranslations.data[0].attributes.MetaTitle : 'Trainings'}</title>
        <meta
          name="description"
          content={
            pagetranslations.data.length > 0
              ? pagetranslations.data[0].attributes.MetaText
              : 'Q-railing Trainings for Customers'
          }
        ></meta>
      </Helmet>
      <HeaderSlim></HeaderSlim>
      <div className="jobcenter-wrap">
        <div className="jobcenter-header" id="trainingsoverview-header">
          <h1>{pagetranslations.data.length > 0 && pagetranslations.data[0].attributes.FreeTitle1}</h1>
          <div className="jobcenter-header-subheader">
            <p>{pagetranslations.data.length > 0 && pagetranslations.data[0].attributes.FreeText1}</p>

            <div className="jobcenter-header-subheader-inner" id={systemres.data.length == 0 && 'hiddenid'}>
              {pagetranslations.data.length > 0 && (
                <h3>
                  {posicount}{' '}
                  {parseInt(posicount) < 2
                    ? pagetranslations.data[0].attributes.PageTranslations.data[0]
                    : pagetranslations.data[0].attributes.PageTranslations.data[1]}
                </h3>
              )}
              <a
                onClick={() => {
                  document.querySelector('.jobcenter-jobcard').scrollIntoView({ behavior: 'smooth', block: 'center' });
                }}
              >
                <img
                  width="16"
                  height="16"
                  alt="arrow icon"
                  src="https://q-railing.s3.eu-central-1.amazonaws.com/icons/simple-arrow-white.png"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="jobcenter-banner">
          <div>
            <img
              alt="Job Banner-Image left"
              height="432"
              src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/trainingheader.webp"
            />
            <img
              alt="Job Banner-Image middle"
              width="648"
              height="432"
              src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/trainingheaderqrailing.webp"
            />
            <img
              alt="Job Banner-Image right"
              height="432"
              src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/trainingheader2.webp"
            />
          </div>
        </div>
        <div className="jobcenter-inner" id={systemres.data.length == 0 && 'hiddenid'}>
          <h2 id="jobcenter-list-headline" className="TrainingsOverview-inner-headline">
            {pagetranslations.data.length > 0
              ? pagetranslations.data[0].attributes.FreeData1.data[0]
              : 'Available trainings'}{' '}
            <span>{posicount}</span>
          </h2>
          <div className="jobcenter-jobcards">{rendercards()}</div>
        </div>
      </div>
      <div className="TrainingsOverview-contact-wrap" id={systemres.data.length == 0 && 'AddPaddingId'}>
        <div className="TrainingsOverview-contact-inner">
          <h3>{pagetranslations.data.length > 0 && pagetranslations.data[0].attributes.FreeTitle2}</h3>
          <p>{pagetranslations.data.length > 0 && pagetranslations.data[0].attributes.FreeText2}</p>
          <a
            hrefLang={para.count}
            href={
              pagetranslations.data.length > 0
                ? 'mailto:' + pagetranslations.data[0].attributes.FreeData1.data[2]
                : 'mailto:sales.de@q-railing.de'
            }
          >
            {' '}
            {pagetranslations.data.length > 0 ? pagetranslations.data[0].attributes.FreeData1.data[1] : 'Contact us'}
          </a>
        </div>
      </div>
      <NeedHelp color="grey"></NeedHelp>
      <Footernew></Footernew>
    </>
  );
}

export default TrainingsOverview;
