import React, { useEffect, useState } from 'react';
import HeaderSlim from './includes/HeaderSlim';
import Footernew from './includes/Footernew';
import Catalogue from './includes/Catalogue';
import NeedHelp from './includes/NeedHelp';
import './CatalogPage.css';
import FormFileBlock from './includes/FormFileBlock';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';

const CatalogItem = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="CatalogGrid-item">
      <div className="CatalogGrid-outer" onClick={() => setIsOpen(!isOpen)}>
        <div>
          {!isOpen && <h4>{item.attributes.Headline}</h4>}
          {!isOpen && <p>{item.attributes.CardDescription}</p>}
        </div>
        <button className={isOpen ? 'CatalogOpenBtn' : 'CatalogCloseBtn'} onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.0306 9.96997C11.1715 10.1109 11.2506 10.302 11.2506 10.5012C11.2506 10.7005 11.1715 10.8916 11.0306 11.0325C10.8897 11.1734 10.6986 11.2525 10.4993 11.2525C10.3001 11.2525 10.109 11.1734 9.9681 11.0325L5.99997 7.0631L2.0306 11.0312C1.8897 11.1721 1.69861 11.2513 1.49935 11.2513C1.30009 11.2513 1.10899 11.1721 0.968098 11.0312C0.827202 10.8903 0.748047 10.6992 0.748047 10.5C0.748047 10.3007 0.827202 10.1096 0.968098 9.96872L4.93747 6.0006L0.969348 2.03122C0.828451 1.89033 0.749297 1.69923 0.749297 1.49997C0.749297 1.30072 0.828451 1.10962 0.969348 0.968723C1.11024 0.827827 1.30134 0.748672 1.5006 0.748672C1.69986 0.748672 1.89095 0.827827 2.03185 0.968723L5.99997 4.9381L9.96935 0.968098C10.1102 0.827201 10.3013 0.748047 10.5006 0.748047C10.6999 0.748047 10.891 0.827201 11.0318 0.968098C11.1727 1.10899 11.2519 1.30009 11.2519 1.49935C11.2519 1.69861 11.1727 1.8897 11.0318 2.0306L7.06247 6.0006L11.0306 9.96997Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.25 7C13.25 7.19891 13.171 7.38968 13.0303 7.53033C12.8897 7.67098 12.6989 7.75 12.5 7.75H7.75V12.5C7.75 12.6989 7.67098 12.8897 7.53033 13.0303C7.38968 13.171 7.19891 13.25 7 13.25C6.80109 13.25 6.61032 13.171 6.46967 13.0303C6.32902 12.8897 6.25 12.6989 6.25 12.5V7.75H1.5C1.30109 7.75 1.11032 7.67098 0.96967 7.53033C0.829018 7.38968 0.75 7.19891 0.75 7C0.75 6.80109 0.829018 6.61032 0.96967 6.46967C1.11032 6.32902 1.30109 6.25 1.5 6.25H6.25V1.5C6.25 1.30109 6.32902 1.11032 6.46967 0.96967C6.61032 0.829018 6.80109 0.75 7 0.75C7.19891 0.75 7.38968 0.829018 7.53033 0.96967C7.67098 1.11032 7.75 1.30109 7.75 1.5V6.25H12.5C12.6989 6.25 12.8897 6.32902 13.0303 6.46967C13.171 6.61032 13.25 6.80109 13.25 7Z"
                fill="#001020"
              />
            </svg>
          )}
        </button>
      </div>
      {isOpen && (
        <div className="CatalogItem">
          <div className="CatalogItem-inner">
            <h4>{item.attributes.Headline}</h4>
            <p>{item.attributes.Description}</p>

            <div className="CatalogItem-Links Cataloge-view-buttons">
              <a href={item.attributes.Links.data[0][1]} target="_blank">
                {item.attributes.Links.data[0][0]}
              </a>
              {item.attributes.Links.data.length > 1 && (
                <a href={item.attributes.Links.data[1][1]} target="_blank">
                  {item.attributes.Links.data[1][0]}
                </a>
              )}
            </div>
          </div>
          <img src={item.attributes.CardImage2x3.data.attributes.url} alt={item.attributes.Headline} />
        </div>
      )}
    </div>
  );
};

function CatalogPage() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let metatrans = {
    'de-de': [
      'Kataloge und Broschüren | Q-railing',
      'In diesem Bereich können Sie unseren Online-Katalog einsehen und downloaden oder ein physisches Exemplar anfordern.',
    ],
    'de-at': [
      'Kataloge und Broschüren | Q-railing',
      'In diesem Bereich können Sie unseren Online-Katalog einsehen und downloaden oder ein physisches Exemplar anfordern.',
    ],
    'de-ch': [
      'Kataloge und Broschüren | Q-railing',
      'In diesem Bereich können Sie unseren Online-Katalog einsehen und downloaden oder ein physisches Exemplar anfordern.',
    ],
    'en-at': [
      'Catalogues and Brochures | Q-railing',
      'In this section, you can view and download our online catalogue or request a physical copy.',
    ],
    en: [
      'Catalogues and Brochures | Q-railing',
      'In this section, you can view and download our online catalogue or request a physical copy.',
    ],
    es: [
      'Catálogos y folletos | Q-railing',
      'En esta sección puede ver y descargar nuestro catálogo en línea o solicitar una copia física.',
    ],
    'en-us': [
      'Catalogues and Brochures | Q-railing',
      'In this section, you can view and download our online catalogue or request a physical copy.',
    ],
    'en-gb': [
      'Catalogues and Brochures | Q-railing',
      'In this section, you can view and download our online catalogue or request a physical copy.',
    ],
    'en-ca': [
      'Catalogues and Brochures | Q-railing',
      'In this section, you can view and download our online catalogue or request a physical copy.',
    ],
    'en-ie': [
      'Catalogues and Brochures | Q-railing',
      'In this section, you can view and download our online catalogue or request a physical copy.',
    ],
    'es-es': [
      'Catálogos y folletos | Q-railing',
      'En esta sección puede ver y descargar nuestro catálogo en línea o solicitar una copia física.',
    ],
    'es-us': [
      'Catálogos y folletos | Q-railing',
      'En esta sección puede ver y descargar nuestro catálogo en línea o solicitar una copia física.',
    ],
    'da-dk': [
      'Kataloger og brochurer | Q-railing',
      'I dette område kan du se og downloade vores online-katalog eller anmode om en fysisk kopi.',
    ],
    'fi-fi': [
      'Luettelot ja esitteet | Q-railing',
      'Tässä osiossa voit tarkastella ja ladata online-luettelomme tai pyytää fyysistä kopiota.',
    ],
    'fr-fr': [
      'Catalogues et brochures | Q-railing',
      'Dans cette section, vous pouvez consulter et télécharger notre catalogue en ligne ou demander un exemplaire physique.',
    ],
    'fr-ca': [
      'Catalogues et brochures | Q-railing',
      'Dans cette section, vous pouvez consulter et télécharger notre catalogue en ligne ou demander un exemplaire physique.',
    ],
    'fr-be': [
      'Catalogues et brochures | Q-railing',
      'Dans cette section, vous pouvez consulter et télécharger notre catalogue en ligne ou demander un exemplaire physique.',
    ],
    'fr-ch': [
      'Catalogues et brochures | Q-railing',
      'Dans cette section, vous pouvez consulter et télécharger notre catalogue en ligne ou demander un exemplaire physique.',
    ],
    'fr-lu': [
      'Catalogues et brochures | Q-railing',
      'Dans cette section, vous pouvez consulter et télécharger notre catalogue en ligne ou demander un exemplaire physique.',
    ],
    'it-it': [
      'Cataloghi e brochure | Q-railing',
      'In questa sezione è possibile visualizzare e scaricare il nostro catalogo online o richiedere una copia fisica.',
    ],
    'it-ch': [
      'Cataloghi e brochure | Q-railing',
      'In questa sezione è possibile visualizzare e scaricare il nostro catalogo online o richiedere una copia fisica.',
    ],
    'nb-no': [
      'Kataloger og brosjyrer | Q-railing',
      'I dette området kan du se og laste ned vår online katalog eller be om et fysisk eksemplar.',
    ],
    'nl-nl': [
      'Catalogi en brochures | Q-railing',
      'In dit gedeelte kunt u onze online catalogus bekijken en downloaden of een fysiek exemplaar aanvragen.',
    ],
    'nl-be': [
      'Catalogi en brochures | Q-railing',
      'In dit gedeelte kunt u onze online catalogus bekijken en downloaden of een fysiek exemplaar aanvragen.',
    ],
    'pl-pl': [
      'Katalogi i broszury | Q-railing',
      'W tej sekcji możesz przeglądać i pobierać nasz katalog online lub zamówić egzemplarz fizyczny.',
    ],
    'pt-pt': [
      'Catálogos e brochuras | Q-railing',
      'Nesta área, você pode visualizar e baixar nosso catálogo online ou solicitar uma cópia física.',
    ],
    'sv-se': [
      'Kataloger och broschyrer | Q-railing',
      'I det här avsnittet kan du se och ladda ner vår onlinekatalog eller begära ett fysiskt.',
    ],
  };
  let [systemarr, setsystemarr] = useState({ data: [] });

  let para = useParams();
  let param = useParams();
  let la;
  let arrla;
  let arrla1;
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  let metatext = [
    'Catalogues and Brochures | Q-railing',
    'In this section, you can view and download our online catalogue or request a physical copy.',
  ];

  if (para.count) {
    let thetext = metatrans[para.count];
    if (thetext) {
      metatext = thetext;
    }
  }

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/catalogs?locale=' + arrla1 + '&populate=CardImage2x3',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setsystemarr(json);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{metatext[0]}</title>
        <meta name="description" content={metatext[1]} />
      </Helmet>
      <HeaderSlim color="grey"></HeaderSlim>

      <Catalogue color="grey"></Catalogue>

      <div className="CatalogGrid-wrap">
        <div className="CatalogGrid-inner">
          {systemarr.data && systemarr.data.map((item, index) => <CatalogItem key={index} item={item} />)}
        </div>
      </div>

      <FormFileBlock type="catalog" file="nofile"></FormFileBlock>
      <NeedHelp color="grey"></NeedHelp>
      <Footernew></Footernew>
    </>
  );
}

export default CatalogPage;
