import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Utility } from '../../utility';
import { useLocale } from 'hooks/use-locale';
import Pako from 'pako';
import routeTranslations from 'assets/json/route-translations';
import { Routing } from './routing';
import { LoadingScreen } from 'components/loading-screen/loading-screen';

const translateRoute = (route, locale, fromLocale = 'en') => {
  locale ??= window.location.pathname.split('/')[1];
  if (!Utility.isLocaleValid(locale)) throw new Error('Invalid locale provided: ' + locale);

  const ogRoute = route;
  const routeParts = route.split('/').filter((x) => x && x !== '');
  if (routeParts[0].match(/^\w\w(-\w\w)?$/gim)) routeParts.splice(0, 1);
  route = routeParts.join('/');

  // translate route from fromLocale to english (en)
  let fromCurrent = routeTranslations[fromLocale];
  let routingCurrent = Routing;

  for (let i = 0; i < routeParts.length; i++) {
    const part = routeParts[i];

    routeParts[i] =
      Object.keys(fromCurrent)
        // eslint-disable-next-line no-loop-func
        .filter((key) => fromCurrent[key].__name === part)[0] ??
      Object.keys(fromCurrent).find((x) => x[0] === ':') ??
      part;

    fromCurrent =
      fromCurrent[part] ??
      Object.values(fromCurrent).find((x) => x.__name === part) ??
      Object.values(fromCurrent).find((x) => x.__name?.[0] === ':') ??
      routingCurrent[routeParts[i]];

    routingCurrent = routingCurrent[routeParts[i]];

    if (!fromCurrent && !routingCurrent && i + 1 !== routeParts.length)
      throw new Error('Invalid route or fromLocale provided: ' + ogRoute + ', ' + fromLocale);
  }

  route = routeParts.join('/');

  //translate route from english to locale
  const parts = route.split('/');
  routingCurrent = Routing;
  let current = routeTranslations[locale];
  route = '';
  for (const part of parts) {
    current = current?.[part];
    route += (current?.__name ?? part) + '/';
  }

  return '/' + locale + '/' + route;
};

/**
 * checks if a route is valid, takes the locale from the route if not provided
 * @param {string} route
 * @param {string | undefined} givenLocale
 * @returns {string | undefined} the locale of the route if valid, undefined otherwise
 */
const getRouteLanguage = (route, givenLocale = undefined) => {
  const checkedLocales = [];
  const parts = route.split('/').filter((x) => x && x !== '');

  const checkRouteOnLocale = (current, locale) => {
    if (checkedLocales.includes(locale) || !Utility.isLocaleValid(locale)) return false;
    checkedLocales.push(locale);

    console.log(locale);
    console.log(parts);

    for (const part of parts) {
      let englishKey = current
        ? // eslint-disable-next-line no-loop-func
          Object.keys(current).filter((key) =>
            current[key].__name ? current[key].__name === part : key === part,
          )[0] ?? Object.keys(current).filter((key) => key[0] === ':')[0]
        : undefined;

      console.log(englishKey);

      if (current) current = current[englishKey];
      if (!current) return false;
    }

    return current ? locale : undefined;
  };

  givenLocale = givenLocale?.toLowerCase();
  const routeLocale = parts[0].match(/^\w\w(-\w\w)?$/) ? parts.shift() : undefined;
  let isValid = false;

  isValid ||= checkRouteOnLocale(routeTranslations[routeLocale], routeLocale);

  isValid ||= checkRouteOnLocale(routeTranslations[givenLocale], givenLocale);

  isValid ||= checkRouteOnLocale(Routing, 'en');
  isValid ||= checkRouteOnLocale(routeTranslations.en, 'en');

  if (parts.length > 0 && !isValid) {
    const routeLanguageLocale = Object.keys(routeTranslations)
      .sort((x, y) => {
        const splittedX = x.split('-');
        const splittedY = y.split('-');

        let xCounter = 0;
        let yCounter = 0;
        if (splittedX.length === 1) xCounter += 2;
        if (splittedY.length === 1) yCounter += 2;

        if (splittedX[0] === splittedX[1]) xCounter += 1;
        if (splittedY[0] === splittedY[1]) yCounter += 1;

        return yCounter - xCounter;
      })
      .find((rtKey) =>
        Object.keys(routeTranslations[rtKey])
          .map((key) => routeTranslations[rtKey][key].__name ?? key)
          .includes(parts[0]),
      );

    if (routeLanguageLocale) {
      isValid = checkRouteOnLocale(routeTranslations[routeLanguageLocale], routeLanguageLocale);
    }
  }

  const locales = Object.keys(routeTranslations);
  for (let i = 0; !isValid && i < locales.length; i++) {
    const loc = locales[i];

    isValid = checkRouteOnLocale(routeTranslations[loc], loc);
  }
  return isValid;
};
const getAllSubstrings = function (str) {
  const length = str.length;
  return Array.from({ length }, (_, i) => str.substring(0, i)).splice(1);
};

const formatTo = (from, to, current, varIndexes) => {
  if (varIndexes.length > 0) {
    const toSplitted = to.split('/');
    const currentSplitted = current.split('/').filter((x) => x && x.trim() !== '');
    for (const index of varIndexes) {
      toSplitted[index] = currentSplitted[index];
    }
    to = toSplitted.join('/');
  }

  const splat = from.endsWith('*') ? current.substring(from.length - 1) : '';
  if (to.endsWith(':splat')) to = to.replace(':splat', splat);
  return to;
};

export const FallbackWithParams = () => {
  console.log('fallback with params');

  const location = useLocation();
  const locale = useLocale();
  const navigate = useNavigate();

  useEffect(() => {
    const redirect = (route) => {
      // fill in the variables for the route
      if (route.includes('/:')) {
        const path = location.pathname.split('/').filter((x) => x && x.trim() !== '');
        const routeParts = route.split('/').filter((x) => x && x.trim() !== '');
        const offset = routeParts[0].match(/^\w\w(-\w\w)?$/) ? 1 : 0;

        for (let i = 0; i < path.length; i++) {
          if (routeParts[i + offset][0] === ':') {
            routeParts[i + offset] = path[i];
          }
        }

        route = routeParts.join('/');
      }

      if (route.endsWith('index.html')) route = route.substring(0, route.length - 10);
      if (!route.endsWith('/')) route += '/';
      console.log('redirecting to', route);

      // check if the route is going to be changed, else reload the page
      if (
        route
          .split('/')
          .filter((x) => x && x.trim() !== '')
          .join() !==
        location.pathname
          .split('/')
          .filter((x) => x && x.trim() !== '')
          .join()
      ) {
        // remove current link from history to prevent back button from redirecting to the same link
        navigate(route + (location.hash ?? '') + (location.search ?? ''), { replace: true });

        // if the history overwrite did not work, reload the page to prevent a wrong url in the url bar
        if (
          window.location.pathname
            .split('/')
            .filter((x) => x && x.trim() !== '')
            .join() ===
          route
            .split('/')
            .filter((x) => x && x.trim() !== '')
            .join()
        ) {
          window.location.reload();
        }
      } else {
        window.location.reload();
      }
    };

    const bootstrap = async () => {
      const currentParts = location.pathname.split('/').filter((x) => x.trim() !== '');

      if (currentParts[0] !== '_') {
        const routeLanguage =
          currentParts.length > 0 && !currentParts[0]?.match(/^\w\w(-\w\w)?$/)
            ? getRouteLanguage(location.pathname, locale)
            : false;
        console.log('route lang: ' + routeLanguage);

        if (routeLanguage) {
          const translated = translateRoute(location.pathname, locale, routeLanguage);
          if (translated) return redirect(translated);
        }
      }

      const deflated = await fetch('/jsons/redirects.json.deflated', { cache: 'default' }).then((x) => x.arrayBuffer());
      /**
       * @type {[string, string][]}
       */
      const redirects = JSON.parse(Pako.inflate(deflated, { to: 'string' }));

      let currentSplitted = location.pathname.split('/').filter((x) => x && x.trim() !== '');
      if (currentSplitted[0] === '_') currentSplitted.shift();
      let current = currentSplitted.join('/');
      const originalPath = current;

      let currentSubstrings = getAllSubstrings(current);
      for (let [from, to] of redirects) {
        const splittedFrom = from.split('/');
        const varIndexes = splittedFrom.map((x, i) => (x[0] === ':' ? i : -1)).filter((x) => x !== -1);
        if (varIndexes.length > 0) {
          for (const index of varIndexes) {
            from = from.replace(splittedFrom[index], currentSplitted[index]);
          }
        }

        if (
          current === from ||
          current + '*' === from ||
          current + '/*' === from ||
          currentSubstrings.some((sub) => sub + '*' === from)
        ) {
          current = formatTo(from, to, current, varIndexes);
          currentSplitted = current.split('/').filter((x) => x && x.trim() !== '');
          currentSubstrings = getAllSubstrings(current);
        }
      }

      if (current && current !== originalPath) {
        if (current.includes('https://')) {
          window.location.href = current;
        } else {
          redirect(current.startsWith('/') ? current : `/${current}`);
        }
      } else {
        // no redirect path found, get locale and then redirect to 404
        redirect(`/${locale}/404/`);
      }
    };
    if (locale) bootstrap();
  }, [locale, navigate, location]);

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <LoadingScreen scale={1} />
    </div>
  );
};
