import { useParams, Navigate } from 'react-router-dom';

export const NavigateWithParams = ({ to }) => {
  const params = useParams();

  // replace all param placeholders with actual values
  for (const key in params) {
    to = to.replace(':' + key, params[key]);
  }

  return (
    <>
      <Navigate to={to} replace={true} />
    </>
  );
};
