import React, { useEffect, useState } from 'react';

import HeaderSlim from './includes/HeaderSlim';
import './Jobcenter.css';
import { useParams, useLocation } from 'react-router-dom';
import Footernew from './includes/Footernew';
import FormFileBlock from './includes/FormFileBlock';
import { Helmet } from 'react-helmet';

function Jobcenterdetail() {
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const params = useParams();
  let [systemres, setsystemres] = useState({ data: [] });
  let [personioid, setpersonioid] = useState(null);

  let la;
  let arrla;
  let arrla1 = '';
  let param = useParams();
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  const translations = {
    'da-dk': 'Ansøg nu',
    'de-at': 'Jetzt bewerben',
    'de-ch': 'Jetzt bewerben',
    'de-de': 'Jetzt bewerben',
    'x-default': 'Apply now',
    'en-ca': 'Apply now',
    'en-gb': 'Apply now',
    'en-ie': 'Apply now',
    'en-us': 'Apply now',
    es: 'Solicitar ahora',
    'es-es': 'Solicitar ahora',
    'es-us': 'Solicitar ahora',
    'fi-fi': 'Hae nyt',
    'fr-be': 'Postuler maintenant',
    'fr-ca': 'Postuler maintenant',
    'fr-ch': 'Postuler maintenant',
    'fr-lu': 'Postuler maintenant',
    'it-ch': 'Candidarsi ora',
    'it-it': 'Candidarsi ora',
    'nb-no': 'Søk nå',
    'nl-be': 'Nu solliciteren',
    'nl-nl': 'Nu solliciteren',
    'pl-pl': 'Aplikuj teraz',
    'pt-br': 'Candidatar-se agora',
    'pt-pt': 'Candidatar-se agora',
    'sv-se': 'Ansök nu',
  };

  const locale = param.count;
  const translatedText = translations[locale] || translations['x-default'];

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    };
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/jobs?locale=' + arrla1 + '&filters[Slug][$eq]=' + params.joboffer,
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setsystemres(json);
        if (json.data.length == 0) {
          window.location.replace('/' + param.count + '/career/');
        }
        if (json.data.length > 0) {
          if (json.data[0].attributes.PersonioId) {
            setpersonioid(json.data[0].attributes.PersonioId);
          }
        }
      });
  }, []);

  function renderjob() {
    if (systemres.data.length > 0) {
      return (
        <>
          <div className="jobcenterdetail-text">
            <div className="jobcenterdetail-text-headline">
              <h2>{systemres.data[0].attributes.TextHeadline} </h2>
            </div>

            <div className="jobcenterdetail-text-inner">
              <div key={'404text'} dangerouslySetInnerHTML={{ __html: systemres.data[0].attributes.Description }} />
            </div>
          </div>
        </>
      );
    }
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>{systemres.data.length > 0 ? systemres.data[0].attributes.MetaTitle : 'Jobs'}</title>
        <meta
          name="description"
          content={systemres.data.length > 0 ? systemres.data[0].attributes.MetaText : 'Q-railing jobs'}
        ></meta>
      </Helmet>
      <HeaderSlim></HeaderSlim>
      <div className="jobcenterdetail-wrap">
        {systemres.data.length > 0 && (
          <div className="jobcenterdetail jobcenterdetail-detail">
            <div>
              <h2>{systemres.data[0].attributes.Title}</h2>
              <p>
                {systemres.data[0].attributes.SubTitle} - {systemres.data[0].attributes.Location}
              </p>
              {systemres.data[0].attributes.IntroductoryText && (
                <p id="jobcenterdetail-introtext">{systemres.data[0].attributes.IntroductoryText}</p>
              )}
            </div>

            <button
              onClick={() => {
                document.querySelector('.FormFileBlock-inner').scrollIntoView({ behavior: 'smooth', block: 'start' });
              }}
            >
              {translatedText}
            </button>
          </div>
        )}
        <div className="jobcenter-wrap-internal">
          <div className="jobcenter-banner">
            <div>
              <img
                alt="Job Banner-Image left"
                height="432"
                src="https://q-railing.s3.eu-central-1.amazonaws.com/images/job2.jpg"
              />
              <img
                alt="Job Banner-Image middle"
                width="648"
                height="432"
                src="https://q-railing.s3.eu-central-1.amazonaws.com/images/jobs1.jpg"
              />
              <img
                alt="Job Banner-Image right"
                height="432"
                src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/Skeb_744x864.webp"
              />
            </div>
          </div>
        </div>
        <div className="jobcenterdetail-inner">{renderjob()}</div>
      </div>
      <FormFileBlock
        type="job"
        slugdata={params.joboffer}
        jobtitle={systemres.data.length > 0 && systemres.data[0].attributes.Title}
        personioid={personioid}
        jobtype={systemres.data.length > 0 && systemres.data[0].attributes.FullPartTime}
      ></FormFileBlock>
      <Footernew></Footernew>
    </>
  );
}

export default Jobcenterdetail;
